

export class SelectServiceImagePdfPath implements ISelectServiceImagePdfPath {
    /** Service Image PDF link */
    pdf_link!: string;

    constructor(data?: ISelectServiceImagePdfPath) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.pdf_link = _data["pdf_link"];
        }
    }

    static fromJS(data: any): SelectServiceImagePdfPath {
        data = typeof data === 'object' ? data : {};
        let result = new SelectServiceImagePdfPath();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["pdf_link"] = this.pdf_link;
        return data; 
    }
}

export interface ISelectServiceImagePdfPath {
    /** Service Image PDF link */
    pdf_link: string;
}

