

export class ResetPasswordInput implements IResetPasswordInput {
    /** The password of user with 100 character limit */
    password!: string;

    constructor(data?: IResetPasswordInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.password = _data["password"];
        }
    }

    static fromJS(data: any): ResetPasswordInput {
        data = typeof data === 'object' ? data : {};
        let result = new ResetPasswordInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["password"] = this.password;
        return data; 
    }
}

export interface IResetPasswordInput {
    /** The password of user with 100 character limit */
    password: string;
}

