<div class="mb-2" [style]="mainElementStyle">
  @if (!labelHide && labelValue.length > 0) {
  <ng-container>
    <label class="form-label">
      {{labelValue}}
      @if(isRequired){
      <sup>*</sup>
      }
    </label>
  </ng-container>
  }

  <div class="search-dropdown" [ngClass]="{open:shown && list !== undefined && list?.length !== 0}">
    <div class="search-dropdown__btn w-100" [ngClass]="{
      inputClassPrimary: inputType === 'primary',
      inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
      inputClassSecondary: inputType === 'secondary',
      inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
      inputClassWarning: inputType === 'warning',
      inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
      inputClassSuccess: inputType === 'success',
      inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',                
      inputClassError: inputType === 'error',
      inputClassErrorWithoutBorder: inputType === 'errorWithoutBorder',
      inputClassDisabled: disabled}" (click)="show()"><span>{{getValue() || placeholder}}</span></div>
    <div class="search-dropdown__dropdown">
      <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword"
        (keyup)="search(keyword)" #input />
      <div class="search-dropdown__dropdown__scroller">
        @for (item of list; track item;) {
        <div class="search-dropdown__dropdown__item" (click)="onValueChange(item)">
          {{item[labelFieldName]}}
        </div>
        }
        <div class="search-dropdown__dropdown__msg" *ngIf="list?.length == 0">No Result Found</div>
      </div>
    </div>
  </div>
  <!-- @if(control !== undefined){
  <mat-select [ngClass]="{
          inputClassPrimary: inputType === 'primary',
          inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
          inputClassSecondary: inputType === 'secondary',
          inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
          inputClassWarning: inputType === 'warning',
          inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
          inputClassSuccess: inputType === 'success',
          inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',
          inputClassDisabled: disabled}" [placeholder]="placeholder" [id]="id" [style]="selectStyle"
    [formControl]="$any(control)" ngDefaultControl [value]="value" [attr.disabled]="disabled"
    (selectionChange)="onValueChange($event)">
    @for (item of list; track item;) {
    <mat-option [value]="item[valueFieldName]">{{item[labelFieldName]}}</mat-option>
    }
  </mat-select>
  }
  @else{
  <mat-select [ngClass]="{
          inputClassPrimary: inputType === 'primary',
          inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
          inputClassSecondary: inputType === 'secondary',
          inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
          inputClassWarning: inputType === 'warning',
          inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
          inputClassSuccess: inputType === 'success',
          inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',
          inputClassDisabled: disabled}" [placeholder]="placeholder" [id]="id" [style]="selectStyle" ngDefaultControl
    [value]="value" [attr.disabled]="disabled" (selectionChange)="onValueChange($event)">
    @for (item of list; track item;) {
    <mat-option [value]="item[valueFieldName]">{{item[labelFieldName]}}</mat-option>
    }
  </mat-select>
  } -->
  @if (errorMessage.length > 0) {
  <ng-container>
    <label class="error-label">
      {{errorMessage}}
    </label>
  </ng-container>
  }
</div>