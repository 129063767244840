

export class SelectServiceBookingHistoryInput implements ISelectServiceBookingHistoryInput {
    /** Booking From date */
    fromDate!: string;
    /** Booking to date */
    toDate!: string;
    /** Client Name */
    clientName!: string;
    /** Client email address */
    emailAddress!: string;
    /** Status of booking */
    status!: string;
    /** Client mobile no */
    mobileNo!: string;
    /** Service name */
    serviceName!: string;
    /** Service description */
    serviceDescription!: string;
    /** Cleaner Name */
    cleanerName!: string;
    /** City Name */
    cityName!: string;
    /** Car Number */
    carNumber!: string;
    /** Order no */
    orderNo!: string;

    constructor(data?: ISelectServiceBookingHistoryInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fromDate = _data["fromDate"];
            this.toDate = _data["toDate"];
            this.clientName = _data["clientName"];
            this.emailAddress = _data["emailAddress"];
            this.status = _data["status"];
            this.mobileNo = _data["mobileNo"];
            this.serviceName = _data["serviceName"];
            this.serviceDescription = _data["serviceDescription"];
            this.cleanerName = _data["cleanerName"];
            this.cityName = _data["cityName"];
            this.carNumber = _data["carNumber"];
            this.orderNo = _data["orderNo"];
        }
    }

    static fromJS(data: any): SelectServiceBookingHistoryInput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectServiceBookingHistoryInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fromDate"] = this.fromDate;
        data["toDate"] = this.toDate;
        data["clientName"] = this.clientName;
        data["emailAddress"] = this.emailAddress;
        data["status"] = this.status;
        data["mobileNo"] = this.mobileNo;
        data["serviceName"] = this.serviceName;
        data["serviceDescription"] = this.serviceDescription;
        data["cleanerName"] = this.cleanerName;
        data["cityName"] = this.cityName;
        data["carNumber"] = this.carNumber;
        data["orderNo"] = this.orderNo;
        return data; 
    }
}

export interface ISelectServiceBookingHistoryInput {
    /** Booking From date */
    fromDate: string;
    /** Booking to date */
    toDate: string;
    /** Client Name */
    clientName: string;
    /** Client email address */
    emailAddress: string;
    /** Status of booking */
    status: string;
    /** Client mobile no */
    mobileNo: string;
    /** Service name */
    serviceName: string;
    /** Service description */
    serviceDescription: string;
    /** Cleaner Name */
    cleanerName: string;
    /** City Name */
    cityName: string;
    /** Car Number */
    carNumber: string;
    /** Order no */
    orderNo: string;
}

