

export class CityAddInput implements ICityAddInput {
    /** The id of the tbl_state */
    stateId!: number;
    /** The city name with 500 character limit */
    cityName!: string;

    constructor(data?: ICityAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.stateId = _data["stateId"];
            this.cityName = _data["cityName"];
        }
    }

    static fromJS(data: any): CityAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new CityAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["stateId"] = this.stateId;
        data["cityName"] = this.cityName;
        return data; 
    }
}

export interface ICityAddInput {
    /** The id of the tbl_state */
    stateId: number;
    /** The city name with 500 character limit */
    cityName: string;
}

