

export class SignUpUserInput implements ISignUpUserInput {
    /** The id of the user type master */
    userTypeId!: number;
    /** The user type name with 255 character limit */
    userName!: string;
    /** The user mobile number with 13 character limit */
    mobileNo!: string;
    /** The user email address with 320 character limit */
    emailAddress!: string;
    /** The user password with 100 character limit */
    password!: string;

    constructor(data?: ISignUpUserInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTypeId = _data["userTypeId"];
            this.userName = _data["userName"];
            this.mobileNo = _data["mobileNo"];
            this.emailAddress = _data["emailAddress"];
            this.password = _data["password"];
        }
    }

    static fromJS(data: any): SignUpUserInput {
        data = typeof data === 'object' ? data : {};
        let result = new SignUpUserInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTypeId"] = this.userTypeId;
        data["userName"] = this.userName;
        data["mobileNo"] = this.mobileNo;
        data["emailAddress"] = this.emailAddress;
        data["password"] = this.password;
        return data; 
    }
}

export interface ISignUpUserInput {
    /** The id of the user type master */
    userTypeId: number;
    /** The user type name with 255 character limit */
    userName: string;
    /** The user mobile number with 13 character limit */
    mobileNo: string;
    /** The user email address with 320 character limit */
    emailAddress: string;
    /** The user password with 100 character limit */
    password: string;
}

