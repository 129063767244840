import { SelectBlogListDataInputFilter } from './select-blog-list-data-input-filter.model';
import { SelectBlogListDataInputOrderBy } from './select-blog-list-data-input-order-by.model';


export class SelectBlogListDataInput implements ISelectBlogListDataInput {
    /** The search from data */
    search!: string;
    /** The Page no */
    page!: number;
    /** The no of records on per page */
    noOf!: number;
    filter!: SelectBlogListDataInputFilter[];
    orderBy!: SelectBlogListDataInputOrderBy[];

    constructor(data?: ISelectBlogListDataInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.search = _data["search"];
            this.page = _data["page"];
            this.noOf = _data["noOf"];
            if (Array.isArray(_data["filter"])) {
                this.filter = [] as any;
                for (let item of _data["filter"])
                    this.filter!.push(SelectBlogListDataInputFilter.fromJS(item));
            }
            if (Array.isArray(_data["orderBy"])) {
                this.orderBy = [] as any;
                for (let item of _data["orderBy"])
                    this.orderBy!.push(SelectBlogListDataInputOrderBy.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SelectBlogListDataInput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectBlogListDataInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["search"] = this.search;
        data["page"] = this.page;
        data["noOf"] = this.noOf;
        if (Array.isArray(this.filter)) {
            data["filter"] = [];
            for (let item of this.filter)
                data["filter"].push(item.toJSON());
        }
        if (Array.isArray(this.orderBy)) {
            data["orderBy"] = [];
            for (let item of this.orderBy)
                data["orderBy"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ISelectBlogListDataInput {
    /** The search from data */
    search: string;
    /** The Page no */
    page: number;
    /** The no of records on per page */
    noOf: number;
    filter: SelectBlogListDataInputFilter[];
    orderBy: SelectBlogListDataInputOrderBy[];
}

