import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { AddUserAddressInput } from '../models/add-user-address-input.model';
import { AddUserAddressOutput } from '../models/add-user-address-output.model';
import { SelectActiveByUserIdOutput } from '../models/select-active-by-user-id-output.model';
import { SelectAllByUserIdOutput } from '../models/select-all-by-user-id-output.model';
import { SelectByUserAddressIdOutput } from '../models/select-by-user-address-id-output.model';
import { StatusUserAddressInput } from '../models/status-user-address-input.model';
import { StatusUserAddressOutput } from '../models/status-user-address-output.model';
import { UpdateUserAddressInput } from '../models/update-user-address-input.model';
import { UpdateUserAddressOutput } from '../models/update-user-address-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class UserAddressServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return User address added successfully.
     */
    add(body: AddUserAddressInput): Observable<AddUserAddressOutput> {
        let url_ = this.baseUrl + "/UserAddress/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<AddUserAddressOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<AddUserAddressOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<AddUserAddressOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = AddUserAddressOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<AddUserAddressOutput>(<any>null);
    }

    /**
     * @return User address updated successfully.
     */
    update(body: UpdateUserAddressInput): Observable<UpdateUserAddressOutput> {
        let url_ = this.baseUrl + "/UserAddress/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<UpdateUserAddressOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UpdateUserAddressOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<UpdateUserAddressOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UpdateUserAddressOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UpdateUserAddressOutput>(<any>null);
    }

    /**
     * @return User address updated successfully.
     */
    status(body: StatusUserAddressInput): Observable<StatusUserAddressOutput> {
        let url_ = this.baseUrl + "/UserAddress/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<StatusUserAddressOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<StatusUserAddressOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<StatusUserAddressOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = StatusUserAddressOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<StatusUserAddressOutput>(<any>null);
    }

    /**
     * @return All user address successfully.
     */
    selectAllByUserId(userId: number): Observable<SelectAllByUserIdOutput> {
        let url_ = this.baseUrl + "/UserAddress/SelectAllByUserId/{userId}";
        if (userId === undefined || userId === null)
            throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{userId}", encodeURIComponent("" + userId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAllByUserId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllByUserId(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllByUserIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllByUserIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllByUserId(response: HttpResponseBase): Observable<SelectAllByUserIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllByUserIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllByUserIdOutput>(<any>null);
    }

    /**
     * @return Active user address successfully.
     */
    selectActiveByUserId(userId: number): Observable<SelectActiveByUserIdOutput> {
        let url_ = this.baseUrl + "/UserAddress/SelectActiveByUserId/{userId}";
        if (userId === undefined || userId === null)
            throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{userId}", encodeURIComponent("" + userId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActiveByUserId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveByUserId(<any>response_);
                } catch (e) {
                    return <Observable<SelectActiveByUserIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectActiveByUserIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveByUserId(response: HttpResponseBase): Observable<SelectActiveByUserIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectActiveByUserIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectActiveByUserIdOutput>(<any>null);
    }

    /**
     * @return User address selected successfully.
     */
    selectByUserAddressesId(userAddressId: number): Observable<SelectByUserAddressIdOutput> {
        let url_ = this.baseUrl + "/UserAddress/SelectByUserAddressesId/{userAddressId}";
        if (userAddressId === undefined || userAddressId === null)
            throw new Error("The parameter 'userAddressId' must be defined.");
        url_ = url_.replace("{userAddressId}", encodeURIComponent("" + userAddressId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectByUserAddressesId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByUserAddressesId(<any>response_);
                } catch (e) {
                    return <Observable<SelectByUserAddressIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectByUserAddressIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByUserAddressesId(response: HttpResponseBase): Observable<SelectByUserAddressIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectByUserAddressIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectByUserAddressIdOutput>(<any>null);
    }
}
