<div class="mb-2">
    @if (!labelHide && labelValue.length > 0) {
    <ng-container>
        <label class="form-label">
            {{labelValue}}
            @if(isRequired){
            <sup>*</sup>
            }
        </label>
    </ng-container>
    }

    @if (isMultiple) {
    <input [ngClass]="{
            inputClassPrimary: inputType === 'primary',
            inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
            inputClassSecondary: inputType === 'secondary',
            inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
            inputClassWarning: inputType === 'warning',
            inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
            inputClassSuccess: inputType === 'success',
            inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',                
            inputClassError: inputType === 'error',
            inputClassErrorWithoutBorder: inputType === 'errorWithoutBorder',
            inputClassDisabled: disabled}" [formControl]="$any(control)" ngDefaultControl [type]="type" [id]="id"
        [name]="name" [attr.disabled]="disabled" [placeholder]="placeholder" [min]="min" multiple />
    }
    @else {
    <input [ngClass]="{
            inputClassPrimary: inputType === 'primary',
            inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
            inputClassSecondary: inputType === 'secondary',
            inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
            inputClassWarning: inputType === 'warning',
            inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
            inputClassSuccess: inputType === 'success',
            inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',                
            inputClassError: inputType === 'error',
            inputClassErrorWithoutBorder: inputType === 'errorWithoutBorder',
            inputClassDisabled: disabled}" [formControl]="$any(control)" ngDefaultControl [type]="type" [id]="id"
        [name]="name" [attr.disabled]="disabled" [placeholder]="placeholder" [min]="min" />
    }

    @if (errorMessage.length > 0) {
    <ng-container>
        <label class="error-label">
            {{errorMessage}}
        </label>
    </ng-container>
    }

</div>