

export class SelectCouponCodeListDataInputOrderBy implements ISelectCouponCodeListDataInputOrderBy {
    /** The key for order by */
    key!: string;
    /** Order type must be one of "Ascending" or "Descending". */
    orderType!: string;

    constructor(data?: ISelectCouponCodeListDataInputOrderBy) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.key = _data["key"];
            this.orderType = _data["orderType"];
        }
    }

    static fromJS(data: any): SelectCouponCodeListDataInputOrderBy {
        data = typeof data === 'object' ? data : {};
        let result = new SelectCouponCodeListDataInputOrderBy();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["key"] = this.key;
        data["orderType"] = this.orderType;
        return data; 
    }
}

export interface ISelectCouponCodeListDataInputOrderBy {
    /** The key for order by */
    key: string;
    /** Order type must be one of "Ascending" or "Descending". */
    orderType: string;
}

