

export class BulkCleanerAssignServiceBookingInput implements IBulkCleanerAssignServiceBookingInput {
    /** Order Item ID */
    orderItemId!: number;
    /** Pass from booking date you want to update data */
    bookingDate!: string;
    /** Pass to booking date you want to update data */
    bookingTime!: string;
    /** Cleaner user id */
    cleanerUserId!: number;

    constructor(data?: IBulkCleanerAssignServiceBookingInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.orderItemId = _data["orderItemId"];
            this.bookingDate = _data["bookingDate"];
            this.bookingTime = _data["bookingTime"];
            this.cleanerUserId = _data["cleanerUserId"];
        }
    }

    static fromJS(data: any): BulkCleanerAssignServiceBookingInput {
        data = typeof data === 'object' ? data : {};
        let result = new BulkCleanerAssignServiceBookingInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["orderItemId"] = this.orderItemId;
        data["bookingDate"] = this.bookingDate;
        data["bookingTime"] = this.bookingTime;
        data["cleanerUserId"] = this.cleanerUserId;
        return data; 
    }
}

export interface IBulkCleanerAssignServiceBookingInput {
    /** Order Item ID */
    orderItemId: number;
    /** Pass from booking date you want to update data */
    bookingDate: string;
    /** Pass to booking date you want to update data */
    bookingTime: string;
    /** Cleaner user id */
    cleanerUserId: number;
}

