

export class UpdateCouponCodeInput implements IUpdateCouponCodeInput {
    /** The auto generated id from tbl coupon code master */
    couponCodeId!: number;
    /** The coupon code name with 500 character limit */
    couponCodeName!: string;
    /** The coupon code description with 5000 character limit */
    couponCodeDescription!: string;
    /** The coupon code  with 500 character limit */
    couponCode!: string;
    /** The coupon code type should be AMOUNT or PERCENTAGE */
    couponCodeType!: string;
    /** The discount on decided coupon */
    discount!: number;
    /** The maximum discount on amount */
    maximumDiscountAmount!: number;
    /** The minimum amount for discount */
    minimumAmountForDiscount!: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    isForNewUserOrAmountBased!: number;

    constructor(data?: IUpdateCouponCodeInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.couponCodeId = _data["couponCodeId"];
            this.couponCodeName = _data["couponCodeName"];
            this.couponCodeDescription = _data["couponCodeDescription"];
            this.couponCode = _data["couponCode"];
            this.couponCodeType = _data["couponCodeType"];
            this.discount = _data["discount"];
            this.maximumDiscountAmount = _data["maximumDiscountAmount"];
            this.minimumAmountForDiscount = _data["minimumAmountForDiscount"];
            this.isForNewUserOrAmountBased = _data["isForNewUserOrAmountBased"];
        }
    }

    static fromJS(data: any): UpdateCouponCodeInput {
        data = typeof data === 'object' ? data : {};
        let result = new UpdateCouponCodeInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["couponCodeId"] = this.couponCodeId;
        data["couponCodeName"] = this.couponCodeName;
        data["couponCodeDescription"] = this.couponCodeDescription;
        data["couponCode"] = this.couponCode;
        data["couponCodeType"] = this.couponCodeType;
        data["discount"] = this.discount;
        data["maximumDiscountAmount"] = this.maximumDiscountAmount;
        data["minimumAmountForDiscount"] = this.minimumAmountForDiscount;
        data["isForNewUserOrAmountBased"] = this.isForNewUserOrAmountBased;
        return data; 
    }
}

export interface IUpdateCouponCodeInput {
    /** The auto generated id from tbl coupon code master */
    couponCodeId: number;
    /** The coupon code name with 500 character limit */
    couponCodeName: string;
    /** The coupon code description with 5000 character limit */
    couponCodeDescription: string;
    /** The coupon code  with 500 character limit */
    couponCode: string;
    /** The coupon code type should be AMOUNT or PERCENTAGE */
    couponCodeType: string;
    /** The discount on decided coupon */
    discount: number;
    /** The maximum discount on amount */
    maximumDiscountAmount: number;
    /** The minimum amount for discount */
    minimumAmountForDiscount: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    isForNewUserOrAmountBased: number;
}

