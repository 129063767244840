import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { AddServiceWiseCouponCodeInput } from '../models/add-service-wise-coupon-code-input.model';
import { AddServiceWiseCouponCodeOutput } from '../models/add-service-wise-coupon-code-output.model';
import { SelectServiceWiseCouponCodeListDataInput } from '../models/select-service-wise-coupon-code-list-data-input.model';
import { SelectServiceWiseCouponCodeListDataOutput } from '../models/select-service-wise-coupon-code-list-data-output.model';
import { StatusServiceWiseCouponInput } from '../models/status-service-wise-coupon-input.model';
import { StatusServiceWiseCouponOutput } from '../models/status-service-wise-coupon-output.model';
import { UpdateServiceWiseCouponCodeInput } from '../models/update-service-wise-coupon-code-input.model';
import { UpdateServiceWiseCouponCodeOutput } from '../models/update-service-wise-coupon-code-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class ServiceWiseCouponCodeServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return Service wise coupon code added successfully.
     */
    add(body: AddServiceWiseCouponCodeInput): Observable<AddServiceWiseCouponCodeOutput> {
        let url_ = this.baseUrl + "/ServiceWiseCouponCode/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<AddServiceWiseCouponCodeOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<AddServiceWiseCouponCodeOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<AddServiceWiseCouponCodeOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = AddServiceWiseCouponCodeOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<AddServiceWiseCouponCodeOutput>(<any>null);
    }

    /**
     * @return Service wise coupon code updated successfully.
     */
    update(body: UpdateServiceWiseCouponCodeInput): Observable<UpdateServiceWiseCouponCodeOutput> {
        let url_ = this.baseUrl + "/ServiceWiseCouponCode/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<UpdateServiceWiseCouponCodeOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UpdateServiceWiseCouponCodeOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<UpdateServiceWiseCouponCodeOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UpdateServiceWiseCouponCodeOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UpdateServiceWiseCouponCodeOutput>(<any>null);
    }

    /**
     * @return Service wise coupon code updated successfully.
     */
    status(body: StatusServiceWiseCouponInput): Observable<StatusServiceWiseCouponOutput> {
        let url_ = this.baseUrl + "/ServiceWiseCouponCode/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<StatusServiceWiseCouponOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<StatusServiceWiseCouponOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<StatusServiceWiseCouponOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = StatusServiceWiseCouponOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<StatusServiceWiseCouponOutput>(<any>null);
    }

    /**
     * @return All service wise coupon code selected successfully.
     */
    selectServiceWiseCouponCodeListData(body: SelectServiceWiseCouponCodeListDataInput): Observable<SelectServiceWiseCouponCodeListDataOutput> {
        let url_ = this.baseUrl + "/ServiceWiseCouponCode/SelectServiceWiseCouponCodeListData";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectServiceWiseCouponCodeListData(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectServiceWiseCouponCodeListData(<any>response_);
                } catch (e) {
                    return <Observable<SelectServiceWiseCouponCodeListDataOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectServiceWiseCouponCodeListDataOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectServiceWiseCouponCodeListData(response: HttpResponseBase): Observable<SelectServiceWiseCouponCodeListDataOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectServiceWiseCouponCodeListDataOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectServiceWiseCouponCodeListDataOutput>(<any>null);
    }
}
