

export class ServicePackageOutputWithStatus implements IServicePackageOutputWithStatus {
    /** The auto generated id from service package master */
    service_package_id!: number;
    /** The auto generated id from service master */
    service_id!: number;
    /** The auto generated id from service package type master */
    service_package_type_id!: number;
    /** The service package type should be One Time Service,One Week Package,One Month Package,Three Month Package,Six Month Package,One Year Package */
    service_package_type!: string;
    /** The service package mrp */
    service_package_mrp!: number;
    /** The service package selling price */
    service_package_selling_price!: number;
    /** The service days */
    total_days!: number;
    /** The status should be 0 or 1 */
    status!: number;

    constructor(data?: IServicePackageOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.service_package_id = _data["service_package_id"];
            this.service_id = _data["service_id"];
            this.service_package_type_id = _data["service_package_type_id"];
            this.service_package_type = _data["service_package_type"];
            this.service_package_mrp = _data["service_package_mrp"];
            this.service_package_selling_price = _data["service_package_selling_price"];
            this.total_days = _data["total_days"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): ServicePackageOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new ServicePackageOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["service_package_id"] = this.service_package_id;
        data["service_id"] = this.service_id;
        data["service_package_type_id"] = this.service_package_type_id;
        data["service_package_type"] = this.service_package_type;
        data["service_package_mrp"] = this.service_package_mrp;
        data["service_package_selling_price"] = this.service_package_selling_price;
        data["total_days"] = this.total_days;
        data["status"] = this.status;
        return data; 
    }
}

export interface IServicePackageOutputWithStatus {
    /** The auto generated id from service package master */
    service_package_id: number;
    /** The auto generated id from service master */
    service_id: number;
    /** The auto generated id from service package type master */
    service_package_type_id: number;
    /** The service package type should be One Time Service,One Week Package,One Month Package,Three Month Package,Six Month Package,One Year Package */
    service_package_type: string;
    /** The service package mrp */
    service_package_mrp: number;
    /** The service package selling price */
    service_package_selling_price: number;
    /** The service days */
    total_days: number;
    /** The status should be 0 or 1 */
    status: number;
}

