

export class AddDiscountCouponInput implements IAddDiscountCouponInput {
    /** The discount coupon name with 500 character limit */
    discountCouponName!: string;
    /** The discount coupon description with 5000 character limit */
    discountCouponDescription!: string;
    /** The discount coupon type should be AMOUNT or PERCENTAGE */
    discountCouponType!: string;
    /** The discount on decided coupon */
    discount!: number;
    /** The maximum discount on amount */
    maximumDiscountAmount!: number;
    /** The minimum amount for discount */
    minimumAmountForDiscount!: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    isForNewUserOrAmountBased!: number;

    constructor(data?: IAddDiscountCouponInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.discountCouponName = _data["discountCouponName"];
            this.discountCouponDescription = _data["discountCouponDescription"];
            this.discountCouponType = _data["discountCouponType"];
            this.discount = _data["discount"];
            this.maximumDiscountAmount = _data["maximumDiscountAmount"];
            this.minimumAmountForDiscount = _data["minimumAmountForDiscount"];
            this.isForNewUserOrAmountBased = _data["isForNewUserOrAmountBased"];
        }
    }

    static fromJS(data: any): AddDiscountCouponInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddDiscountCouponInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["discountCouponName"] = this.discountCouponName;
        data["discountCouponDescription"] = this.discountCouponDescription;
        data["discountCouponType"] = this.discountCouponType;
        data["discount"] = this.discount;
        data["maximumDiscountAmount"] = this.maximumDiscountAmount;
        data["minimumAmountForDiscount"] = this.minimumAmountForDiscount;
        data["isForNewUserOrAmountBased"] = this.isForNewUserOrAmountBased;
        return data; 
    }
}

export interface IAddDiscountCouponInput {
    /** The discount coupon name with 500 character limit */
    discountCouponName: string;
    /** The discount coupon description with 5000 character limit */
    discountCouponDescription: string;
    /** The discount coupon type should be AMOUNT or PERCENTAGE */
    discountCouponType: string;
    /** The discount on decided coupon */
    discount: number;
    /** The maximum discount on amount */
    maximumDiscountAmount: number;
    /** The minimum amount for discount */
    minimumAmountForDiscount: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    isForNewUserOrAmountBased: number;
}

