import { SelectServiceWiseDiscountListDataOutputData } from './select-service-wise-discount-list-data-output-data.model';


export class SelectServiceWiseDiscountListDataOutput implements ISelectServiceWiseDiscountListDataOutput {
    status!: number;
    message!: string;
    data!: SelectServiceWiseDiscountListDataOutputData;

    constructor(data?: ISelectServiceWiseDiscountListDataOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.status = _data["status"];
            this.message = _data["message"];
            this.data = _data["data"] ? SelectServiceWiseDiscountListDataOutputData.fromJS(_data["data"]) : <any>undefined;
        }
    }

    static fromJS(data: any): SelectServiceWiseDiscountListDataOutput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectServiceWiseDiscountListDataOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["status"] = this.status;
        data["message"] = this.message;
        data["data"] = this.data ? this.data.toJSON() : <any>undefined;
        return data; 
    }
}

export interface ISelectServiceWiseDiscountListDataOutput {
    status: number;
    message: string;
    data: SelectServiceWiseDiscountListDataOutputData;
}

