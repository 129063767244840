

export class ServicePackageTypeOutputWithStatus implements IServicePackageTypeOutputWithStatus {
    /** The auto generated id from service package type master */
    service_package_type_id!: number;
    /** The service package type */
    service_package_type!: string;
    /** The service total days */
    total_days!: number;

    constructor(data?: IServicePackageTypeOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.service_package_type_id = _data["service_package_type_id"];
            this.service_package_type = _data["service_package_type"];
            this.total_days = _data["total_days"];
        }
    }

    static fromJS(data: any): ServicePackageTypeOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new ServicePackageTypeOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["service_package_type_id"] = this.service_package_type_id;
        data["service_package_type"] = this.service_package_type;
        data["total_days"] = this.total_days;
        return data; 
    }
}

export interface IServicePackageTypeOutputWithStatus {
    /** The auto generated id from service package type master */
    service_package_type_id: number;
    /** The service package type */
    service_package_type: string;
    /** The service total days */
    total_days: number;
}

