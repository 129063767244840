

export class UserApplyForEmploymentAddInput implements IUserApplyForEmploymentAddInput {
    /** The user type id as integer */
    userTypeId!: number;

    constructor(data?: IUserApplyForEmploymentAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTypeId = _data["userTypeId"];
        }
    }

    static fromJS(data: any): UserApplyForEmploymentAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new UserApplyForEmploymentAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTypeId"] = this.userTypeId;
        return data; 
    }
}

export interface IUserApplyForEmploymentAddInput {
    /** The user type id as integer */
    userTypeId: number;
}

