

export class AddServiceWiseCouponCodeInput implements IAddServiceWiseCouponCodeInput {
    /** The auto generated id from tbl coupon code master */
    couponCodeId!: number;
    /** The auto generated id from tbl service master */
    serviceId!: number;
    /** The start date with 10 character limit */
    startDate!: string;
    /** The end date with 10 character limit */
    endDate!: string;

    constructor(data?: IAddServiceWiseCouponCodeInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.couponCodeId = _data["couponCodeId"];
            this.serviceId = _data["serviceId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
        }
    }

    static fromJS(data: any): AddServiceWiseCouponCodeInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddServiceWiseCouponCodeInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["couponCodeId"] = this.couponCodeId;
        data["serviceId"] = this.serviceId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        return data; 
    }
}

export interface IAddServiceWiseCouponCodeInput {
    /** The auto generated id from tbl coupon code master */
    couponCodeId: number;
    /** The auto generated id from tbl service master */
    serviceId: number;
    /** The start date with 10 character limit */
    startDate: string;
    /** The end date with 10 character limit */
    endDate: string;
}

