

export class AddServicePackageInput implements IAddServicePackageInput {
    /** The auto generated id from service master */
    serviceId!: number;
    /** The auto generated id from service package type  master */
    servicePackageTypeId!: number;
    /** The service package mrp */
    servicePackageMrp!: number;
    /** The service package selling price */
    servicePackageSellingPrice!: number;

    constructor(data?: IAddServicePackageInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.serviceId = _data["serviceId"];
            this.servicePackageTypeId = _data["servicePackageTypeId"];
            this.servicePackageMrp = _data["servicePackageMrp"];
            this.servicePackageSellingPrice = _data["servicePackageSellingPrice"];
        }
    }

    static fromJS(data: any): AddServicePackageInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddServicePackageInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["serviceId"] = this.serviceId;
        data["servicePackageTypeId"] = this.servicePackageTypeId;
        data["servicePackageMrp"] = this.servicePackageMrp;
        data["servicePackageSellingPrice"] = this.servicePackageSellingPrice;
        return data; 
    }
}

export interface IAddServicePackageInput {
    /** The auto generated id from service master */
    serviceId: number;
    /** The auto generated id from service package type  master */
    servicePackageTypeId: number;
    /** The service package mrp */
    servicePackageMrp: number;
    /** The service package selling price */
    servicePackageSellingPrice: number;
}

