

export class SelectUserApplyForEmploymentListDataInputFilter implements ISelectUserApplyForEmploymentListDataInputFilter {
    /** The key for filter */
    key!: string;
    /** Type must be one of "contains", "equals", "start with" or "end with". */
    type!: string;
    /** The value for filter */
    value!: string;

    constructor(data?: ISelectUserApplyForEmploymentListDataInputFilter) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.key = _data["key"];
            this.type = _data["type"];
            this.value = _data["value"];
        }
    }

    static fromJS(data: any): SelectUserApplyForEmploymentListDataInputFilter {
        data = typeof data === 'object' ? data : {};
        let result = new SelectUserApplyForEmploymentListDataInputFilter();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["key"] = this.key;
        data["type"] = this.type;
        data["value"] = this.value;
        return data; 
    }
}

export interface ISelectUserApplyForEmploymentListDataInputFilter {
    /** The key for filter */
    key: string;
    /** Type must be one of "contains", "equals", "start with" or "end with". */
    type: string;
    /** The value for filter */
    value: string;
}

