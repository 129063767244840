

export class ServiceWiseCouponCodeOutputWithStatus implements IServiceWiseCouponCodeOutputWithStatus {
    /** The auto generated id from tbl service wise coupon code master */
    service_wise_coupon_code_id!: number;
    /** The auto generated id from tbl service master */
    service_id!: number;
    /** The service title with 500 character limit */
    service_name!: string;
    /** The start date with 10 character limit */
    start_date!: string;
    /** The end date with 10 character limit */
    end_date!: string;
    /** Manage status of service wise coupon code. 0 For De-active, 1 For Active */
    status!: number;

    constructor(data?: IServiceWiseCouponCodeOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.service_wise_coupon_code_id = _data["service_wise_coupon_code_id"];
            this.service_id = _data["service_id"];
            this.service_name = _data["service_name"];
            this.start_date = _data["start_date"];
            this.end_date = _data["end_date"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): ServiceWiseCouponCodeOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new ServiceWiseCouponCodeOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["service_wise_coupon_code_id"] = this.service_wise_coupon_code_id;
        data["service_id"] = this.service_id;
        data["service_name"] = this.service_name;
        data["start_date"] = this.start_date;
        data["end_date"] = this.end_date;
        data["status"] = this.status;
        return data; 
    }
}

export interface IServiceWiseCouponCodeOutputWithStatus {
    /** The auto generated id from tbl service wise coupon code master */
    service_wise_coupon_code_id: number;
    /** The auto generated id from tbl service master */
    service_id: number;
    /** The service title with 500 character limit */
    service_name: string;
    /** The start date with 10 character limit */
    start_date: string;
    /** The end date with 10 character limit */
    end_date: string;
    /** Manage status of service wise coupon code. 0 For De-active, 1 For Active */
    status: number;
}

