import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '../../services';
import { Router } from '@angular/router';
import { LocalStorage, Paths } from '../../enums';
@Component({
  selector: 'car-daily-side-nav',
  standalone: false,
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent implements OnInit {

  @Input() isMenuOpen = true;
  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  innerWidth: number = 0;

  adminMenuData: any = [
    // {
    //   "application_menu_id": 1,
    //   "menu_name": "Dashboard",
    //   "mat_icon": "dashboard",
    //   "menu_url": `/${Paths.application}/${Paths.dashboard}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    {
      "application_menu_id": 1,
      "menu_name": "Master",
      "mat_icon": "grading",
      "screen_type": "DETAIL",
      "menu": [
        // {
        //   "application_sub_menu_id": 1,
        //   "menu_name": "User Type",
        //   "menu_url": `/${Paths.application}/${Paths.master}/${Paths.userType}`,
        // },
        {
          "application_sub_menu_id": 1,
          "menu_name": "Country - State - City",
          "menu_url": `/${Paths.application}/${Paths.master}/${Paths.country}`,
        },
        {
          "application_sub_menu_id": 2,
          "menu_name": "Category",
          "menu_url": `/${Paths.application}/${Paths.master}/${Paths.category}`,
        },
        {
          "application_sub_menu_id": 3,
          "menu_name": "Fuel Type",
          "menu_url": `/${Paths.application}/${Paths.master}/${Paths.fuelType}`,
        },
        {
          "application_sub_menu_id": 4,
          "menu_name": "Car Brand - Model",
          "menu_url": `/${Paths.application}/${Paths.master}/${Paths.carBrand}`,
        },
      ]
    },
    {
      "application_menu_id": 2,
      "menu_name": "Car Accessories",
      "mat_icon": "store",
      "menu_url": `/${Paths.application}/${Paths.product}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 3,
      "menu_name": "Car Care Services",
      "mat_icon": "business_center",
      "menu_url": `/${Paths.application}/${Paths.master}/${Paths.service}`,
      "screen_type": "OVERVIEW",
      "menu": []
    }, {
      "application_menu_id": 4,
      "menu_name": "Used Cars",
      "mat_icon": "directions_car",
      "menu_url": `/${Paths.application}/${Paths.master}/${Paths.oldCar}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 5,
      "menu_name": "Discount",
      "mat_icon": "local_offer",
      "menu_url": `/${Paths.application}/${Paths.master}/${Paths.discount}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 6,
      "menu_name": "Coupon Code",
      "mat_icon": "redeem",
      "menu_url": `/${Paths.application}/${Paths.master}/${Paths.couponCode}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 7,
      "menu_name": "Banner",
      "mat_icon": "flag",
      "menu_url": `/${Paths.application}/${Paths.banner}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 8,
      "menu_name": "Blog",
      "mat_icon": "rss_feed",
      "menu_url": `/${Paths.application}/${Paths.blog}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 9,
      "menu_name": "Service Booking",
      "mat_icon": "store",
      "menu_url": `/${Paths.application}/${Paths.serviceRequest}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 10,
      "menu_name": "Cleaner Services",
      "mat_icon": "local_car_wash",
      "menu_url": `/${Paths.application}/${Paths.cleanerService}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 11,
      "menu_name": "Order",
      "mat_icon": "reorder",
      "menu_url": `/${Paths.application}/${Paths.order}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 12,
      "menu_name": "Used Car Inquiry",
      "mat_icon": "list",
      "menu_url": `/${Paths.application}/${Paths.usedCarInquiry}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 13,
      "menu_name": "User",
      "mat_icon": "admin_panel_settings",
      "screen_type": "DETAIL",
      "menu": [
        {
          "application_sub_menu_id": 1,
          "menu_name": "List",
          "menu_url": `/${Paths.application}/${Paths.user}/${Paths.list}`,
        },
        {
          "application_sub_menu_id": 2,
          "menu_name": "Request",
          "menu_url": `/${Paths.application}/${Paths.user}/${Paths.request}`,
        },
        {
          "application_sub_menu_id": 3,
          "menu_name": "Employment",
          "menu_url": `/${Paths.application}/${Paths.user}/${Paths.employment}`,
        }
      ]
    },
    // {
    //   "application_menu_id": 14,
    //   "menu_name": "My Order",
    //   "mat_icon": "reorder",
    //   "menu_url": `/${Paths.application}/${Paths.userOrder}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 15,
    //   "menu_name": "My Service",
    //   "mat_icon": "local_car_wash",
    //   "menu_url": `/${Paths.application}/${Paths.myservice}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 16,
    //   "menu_name": "My Car",
    //   "mat_icon": "directions_car",
    //   "menu_url": `/${Paths.application}/${Paths.userCar}/0`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 17,
    //   "menu_name": "My Address",
    //   "mat_icon": "add_location",
    //   "menu_url": `/${Paths.application}/${Paths.master}/${Paths.userAddress}/0`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 18,
    //   "menu_name": "Profile Update",
    //   "mat_icon": "edit",
    //   "menu_url": `/${Paths.application}/${Paths.profileDetail}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 19,
    //   "menu_name": "Change Password",
    //   "mat_icon": "lock",
    //   "menu_url": `/${Paths.application}/${Paths.changePassword}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 20,
    //   "menu_name": "My Inquiry",
    //   "mat_icon": "help_outline",
    //   "menu_url": `/${Paths.application}/${Paths.userInquiry}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    {
      "application_sub_menu_id": 14,
      "menu_name": "Custom Notification",
      "mat_icon": "notifications",
      "menu_url": `/${Paths.application}/${Paths.master}/${Paths.customNotification}`,
    },
    {
      "application_menu_id": 15,
      "menu_name": "Logout",
      "mat_icon": "exit_to_app",
      "screen_type": "OVERVIEW",
      "menu": []
    },
  ];
  // userMenuData: any = [
  //   // {
  //   //   "application_menu_id": 1,
  //   //   "menu_name": "Dashboard",
  //   //   "mat_icon": "dashboard",
  //   //   "menu_url": `/${Paths.application}/${Paths.dashboard}`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 1,
  //   //   "menu_name": "User",
  //   //   "mat_icon": "admin_panel_settings",
  //   //   "screen_type": "DETAIL",
  //   //   "menu": [
  //   //     {
  //   //       "application_sub_menu_id": 1,
  //   //       "menu_name": "Employment",
  //   //       "menu_url": `/${Paths.application}/${Paths.user}/${Paths.employment}`,
  //   //     },
  //   //   ]
  //   // },
  //   // {
  //   //   "application_menu_id": 2,
  //   //   "menu_name": "My Order",
  //   //   "mat_icon": "reorder",
  //   //   "menu_url": `/${Paths.application}/${Paths.userOrder}`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 3,
  //   //   "menu_name": "My Service",
  //   //   "mat_icon": "local_car_wash",
  //   //   "menu_url": `/${Paths.application}/${Paths.myservice}`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 4,
  //   //   "menu_name": "My Car",
  //   //   "mat_icon": "directions_car",
  //   //   "menu_url": `/${Paths.application}/${Paths.userCar}/0`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 5,
  //   //   "menu_name": "My Address",
  //   //   "mat_icon": "add_location",
  //   //   "menu_url": `/${Paths.application}/${Paths.master}/${Paths.userAddress}/0`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 6,
  //   //   "menu_name": "Profile Update",
  //   //   "mat_icon": "edit",
  //   //   "menu_url": `/${Paths.application}/${Paths.profileDetail}`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 7,
  //   //   "menu_name": "Change Password",
  //   //   "mat_icon": "lock",
  //   //   "menu_url": `/${Paths.application}/${Paths.changePassword}`,
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // },
  //   // {
  //   //   "application_menu_id": 8,
  //   //   "menu_name": "Logout",
  //   //   "mat_icon": "exit_to_app",
  //   //   "screen_type": "OVERVIEW",
  //   //   "menu": []
  //   // }
  // ];

  userCleanerData: any = [
    {
      "application_menu_id": 1,
      "menu_name": "Cleaner Service",
      "mat_icon": "local_car_wash",
      "menu_url": `/${Paths.application}/${Paths.cleanerService}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    // {
    //   "application_menu_id": 2,
    //   "menu_name": "User",
    //   "mat_icon": "admin_panel_settings",
    //   "screen_type": "DETAIL",
    //   "menu": [
    //     // {
    //     //   "application_sub_menu_id": 1,
    //     //   "menu_name": "Employment",
    //     //   "menu_url": `/${Paths.application}/${Paths.user}/${Paths.employment}`,
    //     // },
    //     // {
    //     //   "application_sub_menu_id": 1,
    //     //   "menu_name": "List",
    //     //   "menu_url": `/${Paths.application}/${Paths.user}/${Paths.list}`,
    //     // },
    //     {
    //       "application_sub_menu_id": 1,
    //       "menu_name": "Request",
    //       "menu_url": `/${Paths.application}/${Paths.user}/${Paths.request}`,
    //     }
    //   ]
    // },
    // {
    //   "application_menu_id": 3,
    //   "menu_name": "My Order",
    //   "mat_icon": "reorder",
    //   "menu_url": `/${Paths.application}/${Paths.userOrder}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 4,
    //   "menu_name": "My Service",
    //   "mat_icon": "local_car_wash",
    //   "menu_url": `/${Paths.application}/${Paths.myservice}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 5,
    //   "menu_name": "My Car",
    //   "mat_icon": "directions_car",
    //   "menu_url": `/${Paths.application}/${Paths.userCar}/0`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 6,
    //   "menu_name": "My Address",
    //   "mat_icon": "add_location",
    //   "menu_url": `/${Paths.application}/${Paths.master}/${Paths.userAddress}/0`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 7,
    //   "menu_name": "Profile Update",
    //   "mat_icon": "edit",
    //   "menu_url": `/${Paths.application}/${Paths.profileDetail}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 8,
    //   "menu_name": "Change Password",
    //   "mat_icon": "lock",
    //   "menu_url": `/${Paths.application}/${Paths.changePassword}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    {
      "application_menu_id": 2,
      "menu_name": "Logout",
      "mat_icon": "exit_to_app",
      "screen_type": "OVERVIEW",
      "menu": []
    }
  ];
  userSupervisorData: any = [
    {
      "application_menu_id": 1,
      "menu_name": "Service Request",
      "mat_icon": "store",
      "menu_url": `/${Paths.application}/${Paths.serviceRequest}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 2,
      "menu_name": "Cleaner Service",
      "mat_icon": "local_car_wash",
      "menu_url": `/${Paths.application}/${Paths.cleanerService}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 3,
      "menu_name": "Order",
      "mat_icon": "reorder",
      "menu_url": `/${Paths.application}/${Paths.order}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 4,
      "menu_name": "Used Car Inquiry List",
      "mat_icon": "list",
      "menu_url": `/${Paths.application}/${Paths.usedCarInquiry}`,
      "screen_type": "OVERVIEW",
      "menu": []
    },
    // {
    //   "application_menu_id": 5,
    //   "menu_name": "User",
    //   "mat_icon": "admin_panel_settings",
    //   "screen_type": "DETAIL",
    //   "menu": [
    //     // {
    //     //   "application_sub_menu_id": 1,
    //     //   "menu_name": "Employment",
    //     //   "menu_url": `/${Paths.application}/${Paths.user}/${Paths.employment}`,
    //     // },
    //     {
    //       "application_sub_menu_id": 2,
    //       "menu_name": "List",
    //       "menu_url": `/${Paths.application}/${Paths.user}/${Paths.list}`,
    //     },
    //     {
    //       "application_sub_menu_id": 3,
    //       "menu_name": "Request",
    //       "menu_url": `/${Paths.application}/${Paths.user}/${Paths.request}`,
    //     }
    //   ]
    // },
    // {
    //   "application_menu_id": 6,
    //   "menu_name": "My Order",
    //   "mat_icon": "reorder",
    //   "menu_url": `/${Paths.application}/${Paths.userOrder}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 7,
    //   "menu_name": "My Service",
    //   "mat_icon": "local_car_wash",
    //   "menu_url": `/${Paths.application}/${Paths.myservice}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 5,
    //   "menu_name": "My Car",
    //   "mat_icon": "directions_car",
    //   "menu_url": `/${Paths.application}/${Paths.userCar}/0`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 8,
    //   "menu_name": "My Address",
    //   "mat_icon": "add_location",
    //   "menu_url": `/${Paths.application}/${Paths.master}/${Paths.userAddress}/0`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 9,
    //   "menu_name": "Profile Update",
    //   "mat_icon": "edit",
    //   "menu_url": `/${Paths.application}/${Paths.profileDetail}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    // {
    //   "application_menu_id": 10,
    //   "menu_name": "Change Password",
    //   "mat_icon": "lock",
    //   "menu_url": `/${Paths.application}/${Paths.changePassword}`,
    //   "screen_type": "OVERVIEW",
    //   "menu": []
    // },
    {
      "application_menu_id": 5,
      "menu_name": "Logout",
      "mat_icon": "exit_to_app",
      "screen_type": "OVERVIEW",
      "menu": []
    }
  ];
  menuData: any = [];

  applicationMenuId = 0;
  applicationSubMenuId = 0;

  constructor(private localStorageService: LocalStorageService, private router: Router) {

    const loginData = JSON.parse(this.localStorageService.getData(LocalStorage.loginData));
    const switchType = loginData.role_user_type_name === "" ? loginData.user_type_name : loginData.role_user_type_code;
    switch (switchType) {
      case "Admin":
        this.menuData = this.adminMenuData;
        break;
      // case "User":
      //   this.menuData = this.userMenuData;
      //   break;
      case "Cleaner":
        this.menuData = this.userCleanerData;
        break;
      case "Supervisor":
        this.menuData = this.userSupervisorData;
        break;
      default:
        break;
    }

    this.applicationMenuId = parseInt(this.localStorageService.getData(LocalStorage.applicationMenuId));
    this.applicationSubMenuId = parseInt(this.localStorageService.getData(LocalStorage.applicationSubMenuId));
    if (this.applicationMenuId === 0 || isNaN(this.applicationMenuId)) {
      this.router.events.subscribe((event: any) => {
        if (event.url) {
          for (const key in this.menuData) {
            const element = this.menuData[key];
            if (element.menu_url === event.url) {
              this.applicationMenuId = element.application_menu_id;
              this.applicationSubMenuId = 0;
              break;
            }
            else if (element.screen_type === "DETAIL" && element.menu.length > 0) {
              for (const key in element.menu) {
                const subElement = element.menu[key];
                if (subElement.menu_url === event.url) {
                  this.applicationMenuId = element.application_menu_id;
                  this.applicationSubMenuId = subElement.application_sub_menu_id;
                  break;
                }
              }
            }
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  onHideShow(): void {
    this.menuToggle.emit(!this.isMenuOpen);
  }

  menuClick(menuItem: any) {
    if (menuItem.mat_icon === 'exit_to_app') {
      this.localStorageService.deleteData(LocalStorage.accessToken);
      this.localStorageService.deleteData(LocalStorage.refreshToken);
      this.localStorageService.deleteData(LocalStorage.loginData);
      this.localStorageService.deleteData(LocalStorage.applicationMenuId);
      this.localStorageService.deleteData(LocalStorage.applicationSubMenuId);
      this.localStorageService.deleteData(LocalStorage.firebaseData);
      this.localStorageService.deleteData(LocalStorage.routerPath);
      this.router.navigate(["/" + Paths.authentication]);
    }
    if (menuItem.screen_type === "DETAIL" && menuItem.menu.length > 0) {
      this.applicationMenuId = this.applicationMenuId === menuItem.application_menu_id ? "" : menuItem.application_menu_id;
    }
    else {
      this.openMenu(menuItem.application_menu_id, menuItem.menu_url, true);
    }
  }

  openMenu(mainMenuId: any, url: any, isMainMenu = false) {
    if (isMainMenu) {
      this.applicationMenuId = mainMenuId;
      this.applicationSubMenuId = 0;
      this.localStorageService.setData(LocalStorage.applicationMenuId, mainMenuId + "");
      this.localStorageService.setData(LocalStorage.applicationSubMenuId, "0");
    }
    else {
      this.applicationSubMenuId = mainMenuId;
      this.localStorageService.setData(LocalStorage.applicationMenuId, this.applicationMenuId + "");
      this.localStorageService.setData(LocalStorage.applicationSubMenuId, mainMenuId + "");
    }
    this.router.navigate([url]);
    if (innerWidth <= 991.98) {
      this.menuToggle.emit(false);
    }
  }
  goToHomePage() {
    this.router.navigate([Paths.main]);
  }
}
