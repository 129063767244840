import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    getFieldName(fieldName: any) {
        const group = <FormGroup>fieldName.parent;

        if (!group) {
            return null;
        }

        let name: string = "";

        Object.keys(group.controls).forEach(key => {
            const childControl = group.get(key);

            if (childControl !== fieldName) {
                return;
            }

            name = key;
        });
        return name;
    }

    getFormErrorMessage(fieldName: any, errorMessage: any = []) {
        if (fieldName.touched && fieldName?.errors !== null) {
            const name: any = this.getFieldName(fieldName);
            let errorKey = "";
            Object.keys(fieldName.errors).forEach((key) => {
                errorKey = key;
            });
            if (fieldName.errors[errorKey]) {
                return errorMessage[name][errorKey];
            } else {
                let errorMessage = name + " with ";
                Object.keys(fieldName.errors).forEach((key, index) => {
                    errorMessage += (index > 0 ? ", " : "") + key;
                });
                return errorMessage + " validation failed";
            }
        }
        else {
            return "";
        }
    }

    getFormInputType(fieldName: any, defaultThemeType: any): 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' {
        if (fieldName.valid) {
            return 'success';
        }
        else if (fieldName.touched && fieldName?.errors !== null) {
            return 'error';
        }
        else {
            return defaultThemeType;
        }
    }

}
