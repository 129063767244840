import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'car-daily-multi-select',
  standalone: false,
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss'
})
export class MultiSelectComponent {

  @Input() isRequired = false;
  @Input() classType = '';
  @Input() labelHide = false;
  @Input() labelValue = '';
  @Input() errorMessage = '';
  @Input() disabled = false;
  @Input() id = '';
  @Input() control: AbstractControl | undefined | string;
  @Input() value: any;
  @Input() mainElementStyle: object = {};
  @Input() selectStyle: object = {};
  @Input() inputType: 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' = 'primary';
  @Input() labelFieldName: any;
  @Input() data: any;
}
