

export class StateAddInput implements IStateAddInput {
    /** The State code with 255 character limit */
    countryId!: number;
    /** The State name with 255 character limit */
    stateName!: string;

    constructor(data?: IStateAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.countryId = _data["countryId"];
            this.stateName = _data["stateName"];
        }
    }

    static fromJS(data: any): StateAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new StateAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["countryId"] = this.countryId;
        data["stateName"] = this.stateName;
        return data; 
    }
}

export interface IStateAddInput {
    /** The State code with 255 character limit */
    countryId: number;
    /** The State name with 255 character limit */
    stateName: string;
}

