

export class AddCarEnquiryInput implements IAddCarEnquiryInput {
    /** The id of the tbl_car */
    carId!: number;
    /** The enquiry message with 5000 character limit */
    enquiryMessage!: string;

    constructor(data?: IAddCarEnquiryInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.carId = _data["carId"];
            this.enquiryMessage = _data["enquiryMessage"];
        }
    }

    static fromJS(data: any): AddCarEnquiryInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddCarEnquiryInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["carId"] = this.carId;
        data["enquiryMessage"] = this.enquiryMessage;
        return data; 
    }
}

export interface IAddCarEnquiryInput {
    /** The id of the tbl_car */
    carId: number;
    /** The enquiry message with 5000 character limit */
    enquiryMessage: string;
}

