<!-- <div class="app-header app-container container-fluid d-flex align-items-stretch justify-content-between">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu" (click)="onHideShow()">
            <div class="d-flex align-items-center justify-content-center w-35px h-35px">
                <i class="fa-solid fa-bars fs-3"></i>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
        <div class="app-navbar-item ms-1 ms-md-4">
            <div class="d-flex align-items-center justify-content-center w-35px h-35px cursor-pointer">
                <i class="fa-solid fa-bell fs-3"></i>
                <span
                    class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
            </div>
        </div>
        <div class="app-navbar-item ms-1 ms-md-4">
            <div class="d-flex align-items-center justify-content-center w-35px h-35px cursor-pointer"
                (click)="logout()">
                <i class="fa-solid fa-right-from-bracket fs-3"></i>
            </div>
        </div>
    </div>
</div> -->
<div class="app-header app-container">
    <div class="headerTab container-fluid">
        <div class="d-flex align-items-center py-3 justify-content-between g-10">
            <!-- <div class="d-flex align-items-center justify-content-between"> -->
            <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu"
                (click)="onHideShow()">
                <div class="d-flex align-items-center justify-content-center">
                    <i class="fa fa-solid fa-bars fs-4"></i>
                </div>
            </div>
            <!-- </div> -->
            <div class="d-flex align-items-center" (click)="goToHomePage()">
                <img src="../../../assets/images/CarsDailyWhite.png" class='h-70px app-sidebar-logo-default' />
                <!-- <img src="../../../assets/images/app_mini_logo.png" class='h-70px app-sidebar-logo-minimize' /> -->
            </div>
            <div class="d-flex align-items-center">
                <!-- <div class="app-navbar-item ms-1 ms-md-3">
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                        <i class="errorClr fa-solid fa-location-dot fs-4 mr-md-2"></i>
                        <div class="location ms-3 ms-md-2">
                            <p class="mb-0">Location</p>
                            <span class="text-white">
                                CarsDaily, Vadodara, Gujarat
                            </span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="d-flex align-items-center">
                <div class="app-navbar-item cart cursorPointer position-relative" (click)="wishlist()">
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                        <!-- <i class="fa-regular fa-heart"></i> -->
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 26 26">
                            <path fill="#fff" fill-rule="evenodd"
                                d="M18.922 7.202c-1.61-2.116-4.73-1.775-6.321.354a.75.75 0 0 1-1.201 0c-1.595-2.128-4.711-2.47-6.322-.354-.534.7-.828 1.587-.828 2.585 0 1.654.945 3.22 2.364 4.66 1.41 1.43 3.185 2.634 4.642 3.57.44.282 1.049.281 1.487 0v-.001l.348-.225c1.314-.854 2.83-1.912 4.09-3.142 1.27-1.238 2.21-2.584 2.485-4.004.235-1.205-.021-2.496-.743-3.443Zm1.193-.91C17.965 3.47 14.193 3.836 12 5.975c-2.194-2.137-5.964-2.508-8.115.319-.75.983-1.135 2.197-1.135 3.494 0 2.238 1.27 4.164 2.795 5.712 1.536 1.558 3.435 2.838 4.9 3.78h.001a2.914 2.914 0 0 0 3.108-.001c.116-.074.234-.15.355-.23 1.334-.867 2.95-1.989 4.32-3.325 1.362-1.33 2.55-2.938 2.91-4.792.306-1.576-.013-3.314-1.024-4.638Z" />
                        </svg>
                        @if(wishListData.length > 0){
                        <div>
                            <p class="cartCount">{{wishListData.length}}</p>
                        </div>
                        }
                    </div>
                </div>
                <div class="app-navbar-item mx-2 cart ms-md-2 cursorPointer position-relative" (click)="cartRedirect()">
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                        <!-- <i class="fa fa-solid fa-cart-shopping fs-4 position-relative"></i> -->
                        <img src="../../../assets/images/bags-shopping.png" width="26" />
                        @if(cartData.length > 0){
                        <div>
                            <p class="cartCount">{{cartData.length}}</p>
                        </div>
                        }
                    </div>
                </div>
                <div class="cursorPointer app-navbar-item ms-md-3" (click)="dashboard()">
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                        <i class="fa-solid fa-user fs-4 text-white"></i>
                        <!-- <img src="../../../assets/images/profile.png" alt="profile" class="w-100 profileimg"> -->
                    </div>
                </div>
                <!-- <div class="app-navbar-item ms-1 ms-md-3">
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                        <i class="fa fa-solid fa-bell fs-4"></i>
                        <span
                            class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                    </div>
                </div> -->
                <!-- <div class="app-navbar-item ms-1 ms-md-3">
                    <div class="d-flex align-items-center justify-content-center cursor-pointer" (click)="logout()">
                        <i class="fa fa-solid fa-right-from-bracket fs-4"></i>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>