

export class AddRatingReviewInput implements IAddRatingReviewInput {
    /** The productID or ServiceID or CleanerId of user master */
    productServiceCleanerId!: number;
    /** The ratingReview type should be "SERVICE" or "PRODUCT" or "CLEANER" */
    reviewFor!: string;
    /** The product, service, or cleaner rating from 1 to 5 */
    rating!: number;
    /** The review text */
    reviewText!: string;
    /** The orderItemId of order items */
    orderItemId!: number;

    constructor(data?: IAddRatingReviewInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.productServiceCleanerId = _data["productServiceCleanerId"];
            this.reviewFor = _data["reviewFor"];
            this.rating = _data["rating"];
            this.reviewText = _data["reviewText"];
            this.orderItemId = _data["orderItemId"];
        }
    }

    static fromJS(data: any): AddRatingReviewInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddRatingReviewInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["productServiceCleanerId"] = this.productServiceCleanerId;
        data["reviewFor"] = this.reviewFor;
        data["rating"] = this.rating;
        data["reviewText"] = this.reviewText;
        data["orderItemId"] = this.orderItemId;
        return data; 
    }
}

export interface IAddRatingReviewInput {
    /** The productID or ServiceID or CleanerId of user master */
    productServiceCleanerId: number;
    /** The ratingReview type should be "SERVICE" or "PRODUCT" or "CLEANER" */
    reviewFor: string;
    /** The product, service, or cleaner rating from 1 to 5 */
    rating: number;
    /** The review text */
    reviewText: string;
    /** The orderItemId of order items */
    orderItemId: number;
}

