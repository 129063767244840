<div [ngClass]="{
    'view-column': isColumnView
}">
    @for (dataItem of data; track dataItem;let index = $index) {
    <div class="radio-group" [ngClass]="{
            'mb-0': marginBottomZero
        }" (click)="onInputChange(index)">
        <i [ngClass]="{
            'fa-solid fa-circle-check': dataItem.isChecked,
            'fa-regular fa-circle': !dataItem.isChecked
        }"></i>
        <label>{{dataItem.label}}</label>
    </div>
    }
</div>