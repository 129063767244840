

export class SelectServiceBookingByCleanerInput implements ISelectServiceBookingByCleanerInput {
    /** Booking From date */
    fromDate!: string;
    /** Booking to date */
    toDate!: string;

    constructor(data?: ISelectServiceBookingByCleanerInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fromDate = _data["fromDate"];
            this.toDate = _data["toDate"];
        }
    }

    static fromJS(data: any): SelectServiceBookingByCleanerInput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectServiceBookingByCleanerInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fromDate"] = this.fromDate;
        data["toDate"] = this.toDate;
        return data; 
    }
}

export interface ISelectServiceBookingByCleanerInput {
    /** Booking From date */
    fromDate: string;
    /** Booking to date */
    toDate: string;
}

