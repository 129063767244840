@if (pageTitle) {
<div class="pv-075">
    <!-- begin::Title -->
    <h1>
        {{ pageTitle }}
    </h1>
    <!-- end::Title -->
    @if (pageBreadcrumb.length > 0) {
    <ng-container>
        <ul class="breadcrumb">
            @for (breadcrumb of pageBreadcrumb; track breadcrumb) {
            <li class="breadcrumb-item">
                @if (!breadcrumb.path) {
                <ng-container>
                    <span class="text-muted">
                        {{ breadcrumb.title }}
                    </span>
                </ng-container>
                }
                @if (breadcrumb.path) {
                <ng-container>
                    <a class="text-muted" [routerLink]="breadcrumb.path">
                        {{ breadcrumb.title }}
                    </a>
                </ng-container>
                }
            </li>
            }

            <li class="breadcrumb-item text-gray-900">{{ pageTitle }}</li>
        </ul>
    </ng-container>
    }
</div>
}