import { Injectable } from '@angular/core';
import cryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  deleteAllData(): void {
    localStorage.clear();
  }

  getData(item: string): any {
    if (typeof localStorage !== 'undefined') {
      const encodeData = localStorage.getItem(item);
      if (encodeData) {
        const decodeData = cryptoJS.AES.decrypt(encodeData, 'encoded data from Car-daily').toString(cryptoJS.enc.Utf8);
        return decodeData;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  setData(item: string, values: any): void {
    const cryptoData: any = cryptoJS.AES.encrypt(values, 'encoded data from Car-daily');
    localStorage.setItem(item, cryptoData);
  }

  deleteData(item: string): void {
    localStorage.removeItem(item);
  }
}
