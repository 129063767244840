

export class ForgotPasswordInput implements IForgotPasswordInput {
    /** The user email address or mobile number with 320 character limit */
    emailOrMobileNo!: number;

    constructor(data?: IForgotPasswordInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.emailOrMobileNo = _data["emailOrMobileNo"];
        }
    }

    static fromJS(data: any): ForgotPasswordInput {
        data = typeof data === 'object' ? data : {};
        let result = new ForgotPasswordInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["emailOrMobileNo"] = this.emailOrMobileNo;
        return data; 
    }
}

export interface IForgotPasswordInput {
    /** The user email address or mobile number with 320 character limit */
    emailOrMobileNo: number;
}

