import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'car-daily-demo-side-nav',
  standalone: false,
  templateUrl: './demo-side-nav.component.html',
  styleUrl: './demo-side-nav.component.scss'
})
export class DemoSideNavComponent implements OnInit {

  @Input() isMenuOpen = true;
  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  innerWidth: number = 0;

  menuData: any = [
    {
      "application_menu_id": 0,
      "menu_name": "Button",
      "mat_icon": "check_box_outline_blank",
      "menu_url": "/demo/button",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Card",
      "mat_icon": "view_timeline",
      "menu_url": "/demo/card",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Dialog",
      "mat_icon": "warning",
      "menu_url": "/demo/dialog",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Form",
      "mat_icon": "description",
      "menu_url": "/demo/form",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Input",
      "mat_icon": "input",
      "menu_url": "/demo/input",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Radio CheckBox",
      "mat_icon": "check_box",
      "menu_url": "/demo/radio",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Select",
      "mat_icon": "arrow_drop_down_circle",
      "menu_url": "/demo/select",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Table",
      "mat_icon": "table_view",
      "menu_url": "/demo/table",
      "screen_type": "OVERVIEW",
      "menu": []
    },
    {
      "application_menu_id": 0,
      "menu_name": "Server Table",
      "mat_icon": "table_view",
      "menu_url": "/demo/server-table",
      "screen_type": "OVERVIEW",
      "menu": []
    }
  ];

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  onHideShow(): void {
    this.menuToggle.emit(!this.isMenuOpen);
  }

  menuClick(menuItem: any) {
    this.openMenu(menuItem.menu_url);
  }

  openMenu(url: any) {
    this.router.navigate([url]);
    if (innerWidth <= 991.98) {
      this.menuToggle.emit(false);

    }
  }
}
