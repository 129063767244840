

export class AddProductStockInput implements IAddProductStockInput {
    /** The product Id */
    productId!: number;
    /** The stock in quantity */
    stock!: number;

    constructor(data?: IAddProductStockInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.productId = _data["productId"];
            this.stock = _data["stock"];
        }
    }

    static fromJS(data: any): AddProductStockInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddProductStockInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["productId"] = this.productId;
        data["stock"] = this.stock;
        return data; 
    }
}

export interface IAddProductStockInput {
    /** The product Id */
    productId: number;
    /** The stock in quantity */
    stock: number;
}

