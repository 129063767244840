

export class StatusCartInput implements IStatusCartInput {
    /** The cart id of cart master */
    cartId!: number;
    /** The updated status */
    status!: number;

    constructor(data?: IStatusCartInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.cartId = _data["cartId"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): StatusCartInput {
        data = typeof data === 'object' ? data : {};
        let result = new StatusCartInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["cartId"] = this.cartId;
        data["status"] = this.status;
        return data; 
    }
}

export interface IStatusCartInput {
    /** The cart id of cart master */
    cartId: number;
    /** The updated status */
    status: number;
}

