import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'car-daily-radio',
  standalone: false,
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss'
})
export class RadioComponent {

  @Input() data: any = [];
  @Input() isColumnView: boolean = true;
  @Input() marginBottomZero: boolean = false;

  @Output() onRadioChangeEvent: EventEmitter<any> = new EventEmitter();

  onInputChange(index: number) {
    for (const key in this.data) {
      const element = this.data[key];
      element.isChecked = false;
    }
    this.data[index].isChecked = true;
    this.onRadioChangeEvent.emit(this.data[index]);
  }

}
