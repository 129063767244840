import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appClickedOutside]'
})
export class ClickedOutsideDirective {

    constructor(private el: ElementRef) { }

    @Output() public clickedOutside = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(target: any) {
        const element: HTMLElement = target as HTMLElement;
        const clickedInside = this.el.nativeElement.contains(target);
        if (!clickedInside) {
            if (!element.className.toString().includes("mdc")) {
                this.clickedOutside.emit(target);
            }
        }
    }

}