

export class ProductWiseCouponCodeOutputWithStatus implements IProductWiseCouponCodeOutputWithStatus {
    /** The auto generated id from tbl product wise coupon code master */
    product_wise_coupon_code_id!: number;
    /** The auto generated id from tbl product master */
    product_id!: number;
    /** The product title with 500 character limit */
    product_title!: string;
    /** The start date with 10 character limit */
    start_date!: string;
    /** The end date with 10 character limit */
    end_date!: string;
    /** Manage status of product wise coupon code. 0 For De-active, 1 For Active */
    status!: number;

    constructor(data?: IProductWiseCouponCodeOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.product_wise_coupon_code_id = _data["product_wise_coupon_code_id"];
            this.product_id = _data["product_id"];
            this.product_title = _data["product_title"];
            this.start_date = _data["start_date"];
            this.end_date = _data["end_date"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): ProductWiseCouponCodeOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new ProductWiseCouponCodeOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["product_wise_coupon_code_id"] = this.product_wise_coupon_code_id;
        data["product_id"] = this.product_id;
        data["product_title"] = this.product_title;
        data["start_date"] = this.start_date;
        data["end_date"] = this.end_date;
        data["status"] = this.status;
        return data; 
    }
}

export interface IProductWiseCouponCodeOutputWithStatus {
    /** The auto generated id from tbl product wise coupon code master */
    product_wise_coupon_code_id: number;
    /** The auto generated id from tbl product master */
    product_id: number;
    /** The product title with 500 character limit */
    product_title: string;
    /** The start date with 10 character limit */
    start_date: string;
    /** The end date with 10 character limit */
    end_date: string;
    /** Manage status of product wise coupon code. 0 For De-active, 1 For Active */
    status: number;
}

