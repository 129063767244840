import {InjectionToken, Provider} from '@angular/core';

const defaults = {
  radius: 125,
  animation: 'easeOutCubic',
  animationDelay: undefined,
  duration: 500,
  stroke: 15,
  color: '#45CCCE',
  background: '#EAEAEA',
  responsive: false,
  clockwise: true,
  semicircle: false,
  rounded: false,
};
export interface RoundProgressDefaults1 {
  radius?: number;
  animation?: string;
  animationDelay?: number;
  duration?: number;
  stroke?: number;
  color?: string;
  background?: string;
  responsive?: boolean;
  clockwise?: boolean;
  semicircle?: boolean;
  rounded?: boolean;
}

export const roundProgressDefaults =
    new InjectionToken<RoundProgressDefaults1>('roundProgressDefaults', {
      providedIn: 'root',
      factory: () => defaults
    });

export const roundProgressDefaultsProvider: Provider = {
  provide: roundProgressDefaults,
  useValue: defaults,
};

