

export class AddCarBrandInput implements IAddCarBrandInput {
    /** The car brand name with 500 character limit */
    carBrandName!: string;

    constructor(data?: IAddCarBrandInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.carBrandName = _data["carBrandName"];
        }
    }

    static fromJS(data: any): AddCarBrandInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddCarBrandInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["carBrandName"] = this.carBrandName;
        return data; 
    }
}

export interface IAddCarBrandInput {
    /** The car brand name with 500 character limit */
    carBrandName: string;
}

