

export class UserCarServiceStatus implements IUserCarServiceStatus {
    /** The car model name with 50 character limit */
    car_model_name!: string;
    /** The car brand name with 50 character limit */
    car_brand_name!: string;
    /** The car number with 50 character limit */
    car_number!: string;
    /** The car photo with 2048 character limit */
    car_model_picture!: string;
    /** The car service status */
    status!: string;

    constructor(data?: IUserCarServiceStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.car_model_name = _data["car_model_name"];
            this.car_brand_name = _data["car_brand_name"];
            this.car_number = _data["car_number"];
            this.car_model_picture = _data["car_model_picture"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): UserCarServiceStatus {
        data = typeof data === 'object' ? data : {};
        let result = new UserCarServiceStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["car_model_name"] = this.car_model_name;
        data["car_brand_name"] = this.car_brand_name;
        data["car_number"] = this.car_number;
        data["car_model_picture"] = this.car_model_picture;
        data["status"] = this.status;
        return data; 
    }
}

export interface IUserCarServiceStatus {
    /** The car model name with 50 character limit */
    car_model_name: string;
    /** The car brand name with 50 character limit */
    car_brand_name: string;
    /** The car number with 50 character limit */
    car_number: string;
    /** The car photo with 2048 character limit */
    car_model_picture: string;
    /** The car service status */
    status: string;
}

