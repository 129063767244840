

export class AddCartInput implements IAddCartInput {
    /** The user id of user master */
    userId!: number;
    /** The cart list type should be "SERVICE" or "PRODUCT" */
    cartListType!: string;
    /** The product service id will be service id or product id from  product  or service master */
    productServiceId!: number;
    /** The count of product or service added */
    count!: number;
    /** The service package id from service package master */
    servicePackageId!: number;

    constructor(data?: IAddCartInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.cartListType = _data["cartListType"];
            this.productServiceId = _data["productServiceId"];
            this.count = _data["count"];
            this.servicePackageId = _data["servicePackageId"];
        }
    }

    static fromJS(data: any): AddCartInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddCartInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["cartListType"] = this.cartListType;
        data["productServiceId"] = this.productServiceId;
        data["count"] = this.count;
        data["servicePackageId"] = this.servicePackageId;
        return data; 
    }
}

export interface IAddCartInput {
    /** The user id of user master */
    userId: number;
    /** The cart list type should be "SERVICE" or "PRODUCT" */
    cartListType: string;
    /** The product service id will be service id or product id from  product  or service master */
    productServiceId: number;
    /** The count of product or service added */
    count: number;
    /** The service package id from service package master */
    servicePackageId: number;
}

