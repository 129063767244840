import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { saveAs } from 'file-saver';
import { FilterOrderByDialogComponent } from './filter-order-by-dialog/filter-order-by-dialog.component';
import { Subject, debounceTime, pairwise, startWith } from 'rxjs';
import { CheckNullUndefinedService, DateTransformService, IMAGE_BASE_URL } from '../../services';

@Component({
  selector: 'car-daily-table',
  standalone: false,
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isServerSide: boolean = false;
  @Input() columnsShow: boolean = true;
  @Input() filterShow: boolean = true;
  @Input() filterOnMultipleColumn: boolean = false;
  @Input() sortShow: boolean = true;
  @Input() sortOnMultipleColumn: boolean = false;
  @Input() exportShow: boolean = true;
  @Input() paging: boolean = true;
  @Input() displayData: any;
  @Input() defaultFilter: any;
  @Input() defaultSort: any;
  @Input() tableTitle = '';
  @Input() rows: any;
  @Input() columns: any;
  @Input() currentPage: number = 1;
  @Input() pageSize: number = 10;
  @Input() totalRecord: number = 0;
  @Input() totalPages: number = 0;
  @Input() downloadFileName = 'Data';
  @Input() responsiveClass = 'col-12 xol-sm-12';

  @Output() searchOnTable: EventEmitter<any> = new EventEmitter();
  @Output() changePageSize: EventEmitter<any> = new EventEmitter();
  @Output() prevNextClick: EventEmitter<any> = new EventEmitter();
  @Output() filterAndSort: EventEmitter<any> = new EventEmitter();
  @Output() downloadExcel: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() redirectClick: EventEmitter<any> = new EventEmitter();
  @Output() redirectProductClick: EventEmitter<any> = new EventEmitter();
  @Output() redirectServiceClick: EventEmitter<any> = new EventEmitter();
  @Output() activeDeactiveClick: EventEmitter<any> = new EventEmitter();
  @Output() moreInfoDialogClick: EventEmitter<any> = new EventEmitter();
  @Output() verifiedClick: EventEmitter<any> = new EventEmitter();
  @Output() stausRejectClick: EventEmitter<any> = new EventEmitter();
  @Output() onRadioCheckboxChange: EventEmitter<any> = new EventEmitter();
  @Output() onSelectValueChange: EventEmitter<any> = new EventEmitter();
  @Output() onCleanerValueChange: EventEmitter<any> = new EventEmitter();
  @Output() onDownloadServicePDF: EventEmitter<any> = new EventEmitter();
  @Output() productServiceRatingChange: EventEmitter<any> = new EventEmitter();

  private searchSubject = new Subject<string>();
  private filterSubject = new Subject<string>();
  private readonly debounceTimeMs = 300; // Set the debounce time (in milliseconds)

  imageURL: any = "";
  arrayValid = false;
  uniqueColumnName = "";
  filterForm = new FormGroup({
    columns: new FormControl(''),
    operator: new FormControl('contains'),
    filterValue: new FormControl('')
  });
  filterValues: any = [];
  sortForm = new FormGroup({
    columns: new FormControl('Default'),
    orderType: new FormControl('Default'),
  });
  sortValues: any = [];
  selectFormControl = new FormControl('');
  pageSizeFormControl = new FormControl(10);
  orderStatusFormControl = new FormControl();
  columnsPopupHideShow: boolean = false;
  filterPopupHideShow: boolean = false;
  sortPopupHideShow: boolean = false;

  displayColumns: any = [];
  columnsOptions: any = [];
  operatorOptionsList: any = [
    {
      title: "Contains",
      value: "contains"
    },
    {
      title: "Equals",
      value: "equals"
    },
    {
      title: "Start with",
      value: "start with"
    },
    {
      title: "End with",
      value: "end with"
    }
  ];
  filterOptions: any = [];
  sortByOptions: any = [];
  sortOptionsList: any = [
    {
      title: "Default",
      value: "Default"
    },
    {
      title: "Ascending",
      value: "Ascending"
    },
    {
      title: "Descending",
      value: "Descending"
    }
  ];

  filteredRecords: any;
  currentRecords: any;
  searchText = '';
  deleteIndex = -1;
  statusIndex = -1;
  startRecordFrom: number = 0;
  startFrom: number = 0;
  endTo: number = 0;
  innerWidth: number = 0;
  pageSizeList: any = [
    {
      title: 10,
      value: 10
    },
    {
      title: 20,
      value: 20
    },
    {
      title: 50,
      value: 50
    },
    {
      title: 100,
      value: 100
    }
  ];

  isReset = false;
  @ViewChild('formElement') formElement!: ElementRef;
  constructor(
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public dateTransformService: DateTransformService,
    private nullBlankUndefinedService: CheckNullUndefinedService,
    @Inject(IMAGE_BASE_URL) imageURL?: string) {
    this.imageURL = imageURL;
  }

  get filterFormControls(): any {
    return this.filterForm.controls;
  }

  get sortFormControls(): any {
    return this.sortForm.controls;
  }

  clickedOutside() {
    this.columnsPopupHideShow = false;
    this.filterPopupHideShow = false;
    this.sortPopupHideShow = false;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  performFilterSearch() {
    if (this.isServerSide) {
      this.applyFilterAPI();
    }
    else {
      this.applySearch();
    }
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.performSearch(searchValue);
    });

    this.filterSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
      this.performFilterSearch();
    });

    this.pageSizeFormControl.valueChanges.subscribe((value: any) => {
      if (this.pageSize !== parseInt(value)) {
        this.pageSize = parseInt(value);
        if (this.isServerSide) {
          this.changePageSize.emit(this.pageSize);
        }
        else {
          this.loadCurrentPageRecord();
        }
      }
    });

    this.filterFormControls.columns.valueChanges.pipe(startWith(this.filterFormControls.columns.value), pairwise()).subscribe(([prev, next]: [any, any]) => {
      if (!this.filterOnMultipleColumn && prev !== next && !this.isReset && this.filterFormControls.filterValue.value.trim().length > 0) {
        if (this.isServerSide) {
          this.applyFilterAPI();
        }
        else {
          this.applySearch();
        }
      }
    });

    this.filterFormControls.operator.valueChanges.pipe(startWith(this.filterFormControls.operator.value), pairwise()).subscribe(([prev, next]: [any, any]) => {
      if (!this.filterOnMultipleColumn && prev !== next && !this.isReset && this.filterFormControls.filterValue.value.trim().length > 0) {
        if (this.isServerSide) {
          this.applyFilterAPI();
        }
        else {
          this.applySearch();
        }
      }
    });

    this.filterFormControls.filterValue.valueChanges.subscribe(() => {
      if (!this.isReset) {
        this.filterSubject.next(this.filterFormControls.filterValue.value);
      }
    });

    this.sortFormControls.columns.valueChanges.pipe(startWith(this.sortFormControls.columns.value), pairwise()).subscribe(([prev, next]: [any, any]) => {
      if (!this.sortOnMultipleColumn && prev !== next && !this.isReset) {
        if (this.isServerSide) {
          this.applyFilterAPI();
        }
        else {
          this.applySearch();
        }
      }
    });

    this.sortFormControls.orderType.valueChanges.pipe(startWith(this.sortFormControls.orderType.value), pairwise()).subscribe(([prev, next]: [any, any]) => {
      if (!this.sortOnMultipleColumn && prev !== next && !this.isReset) {
        if (this.isServerSide) {
          this.applyFilterAPI();
        }
        else {
          this.applySearch();
        }
      }
    });
  }

  ngOnDestroy() {
    this.searchSubject.complete();
    this.filterSubject.complete();
  }

  ngOnChanges() {
    if (this.defaultFilter) {
      this.filterValues = this.defaultFilter;
    }
    if (this.defaultSort) {
      this.sortValues = this.defaultSort;
    }
    this.pageSizeFormControl.setValue(this.pageSize);
    this.resetColumn();
    this.arrayValid = true;
    for (const key in this.columns) {
      const element = this.columns[key];
      if (element.type === "radio" || element.type === "checkbox") {
        this.arrayValid = element.uniqueColumn !== undefined && element.uniqueColumn !== null && element.uniqueColumn.trim().length > 0;
        if (this.arrayValid && (this.uniqueColumnName === '' || this.uniqueColumnName === element.uniqueColumn.trim())) {
          this.uniqueColumnName = element.uniqueColumn.trim();
        }
        else {
          this.arrayValid = false;
        }
      }
    }
    this.filteredRecords = JSON.parse(JSON.stringify(this.rows));
    if (this.isServerSide) {
      this.currentRecords = this.rows;
      this.startRecordFrom = (this.currentPage - 1) * this.pageSize;
      this.startFrom = this.startRecordFrom + 1;
      this.endTo = (this.startRecordFrom + this.pageSize) > this.totalRecord ? this.totalRecord : (this.startRecordFrom + this.pageSize);
    }
    else {
      this.performSearch(this.searchText);
    }
  }

  loadCurrentPageRecord() {
    if (this.paging) {
      const indexOfLastRecord = this.currentPage * this.pageSize;
      const indexOfFirstRecord = indexOfLastRecord - this.pageSize;
      this.currentRecords = this.filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);
    }
    else {
      this.currentRecords = this.filteredRecords;
    }
    this.loadPagingDetail();
  }

  onPageChange(pageCount: number, type: string) {
    if (this.formElement && this.formElement.nativeElement) {
      this.formElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (this.currentPage > 1 && type === 'prev') {
      this.currentPage = this.currentPage - pageCount;
      this.loadPageChangeData();
    }
    else if (this.currentPage < this.totalPages && type === 'next') {
      this.currentPage = this.currentPage + pageCount;
      this.loadPageChangeData();
    }
  }

  loadPageChangeData() {
    if (this.isServerSide) {
      this.prevNextClick.emit(this.currentPage);
    }
    else {
      this.loadCurrentPageRecord();
    }
  }

  loadPagingDetail() {
    this.totalRecord = this.filteredRecords.length;
    this.totalPages = Math.ceil(this.totalRecord / this.pageSize);
    this.startRecordFrom = (this.currentPage - 1) * this.pageSize;
    this.startFrom = this.startRecordFrom + 1;
    this.endTo = (this.startRecordFrom + this.pageSize) > this.totalRecord ? this.totalRecord : (this.startRecordFrom + this.pageSize);
  }

  itemValueForRadio = (element: any, displayColumnsItem: any) => {
    return [{
      label: "",
      isChecked: element[displayColumnsItem.field]
    }];
  };

  itemValueForCheckbox = (element: any, displayColumnsItem: any) => {
    return element[displayColumnsItem.field];
  };

  itemValue = (element: any, displayColumnsItem: any, elementIndex: number) => {
    let html: any;
    try {
      switch (displayColumnsItem.field) {
        case "id":
          html = (((this.currentPage - 1) * this.pageSize) + (elementIndex + 1)).toString();
          break;
        case "insurance":
          switch (element[displayColumnsItem.field]) {
            case 0:
              if (element.year_and_month_of_registration === '') {
                html = "N/A";
              }
              else {
                html = "Expired";
              }
              break;
            case 1:
              html = "Insured";
              break;
          }
          break;
        case "status":
          if (displayColumnsItem.type === 'userStatus') {
            switch (element[displayColumnsItem.field]) {
              case 0:
                html = "Pending";
                break;
              case 1:
                html = "Approved";
                break;
              case 2:
                html = "Rejected";
                break;
              case 3:
                html = "Cancelled";
                break;
              case 4:
                html = "Removed";
                break;
            }
          }
          else {
            html = element[displayColumnsItem.field] === 1 ? "Active" : "De-Active";
          }
          break;
        case "is_verified":
          switch (element[displayColumnsItem.field]) {
            case 0:
              html = "Pending";
              break;
            case 1:
              html = "Verified";
              break;
            case 2:
              html = "Cancelled";
              break;
          }
          break;
        case "is_for_new_user_or_amount_based":
          html = element[displayColumnsItem.field] === 1 ? "New User" : "All User";
          break;
        default:
          if (displayColumnsItem.isTrueFalse) {
            html = element[displayColumnsItem.field] === 0 ? "False" : "True";
          }
          else if (displayColumnsItem.type === 'image') {
            if (Array.isArray(element[displayColumnsItem.field])) {
              // const image = element[displayColumnsItem.field][0].car_picture ? element[displayColumnsItem.field][0].car_picture :
              //   element[displayColumnsItem.field][0].product_picture;
              // html = image.length > 0 ? this.imageURL + image : "assets/images/app_logo.png";
              if (element[displayColumnsItem.field][0].car_picture) {
                html = element[displayColumnsItem.field][0].car_picture.length > 0 ? this.imageURL + element[displayColumnsItem.field][0].car_picture : "assets/images/app_logo.png";
              }
              else if (element[displayColumnsItem.field][0].product_picture) {
                html = element[displayColumnsItem.field][0].product_picture.length > 0 ? this.imageURL + element[displayColumnsItem.field][0].product_picture : "assets/images/app_logo.png";
              }
              else if (element[displayColumnsItem.field][0].service_picture) {
                html = element[displayColumnsItem.field][0].service_picture.length > 0 ? this.imageURL + element[displayColumnsItem.field][0].service_picture : "assets/images/app_logo.png";
              }
            }
            else {
              html = element[displayColumnsItem.field].length > 0 ? this.imageURL + element[displayColumnsItem.field] : "assets/images/app_logo.png";
            }
          }
          else if (displayColumnsItem.type === 'date') {
            html = this.dateTransformService.transFormDateWithoutTimeZone(element[displayColumnsItem.field]);
          }
          else if (displayColumnsItem.type === 'dateTime') {
            html = this.dateTransformService.transFormDate(element[displayColumnsItem.field]);
          }
          else if (displayColumnsItem.type === 'monthYear') {
            html = this.dateTransformService.transFormMonthYear(element[displayColumnsItem.field]);
          }
          if (this.nullBlankUndefinedService.checkNullAndUndefinedWithBlankData(element[displayColumnsItem.field]) && displayColumnsItem.type !== 'image') {
            html = this.nullBlankUndefinedService.checkNullAndUndefinedWithBlankData(element[displayColumnsItem.field]);
          }
          if (displayColumnsItem.type === 'recipient_name') {
            html = element[displayColumnsItem.field] === '' ? element.user_name : element[displayColumnsItem.field];
          }
          break;
      }
    } catch (error) {
    }
    return html;
  };

  errorHandler(event: any) {
    event.target.src = "assets/images/app_logo.png";
  }

  onRadioChange(index: number, field: any) {
    for (const key in this.displayColumns) {
      const element = this.displayColumns[key];
      if (element.type === 'radio') {
        this.currentRecords[index][element.field] = element.field === field ? 1 : 0;
      }
    }

    const changeValueId = this.currentRecords[index][this.uniqueColumnName];
    for (const key in this.filteredRecords) {
      const element = this.filteredRecords[key];
      if (element[this.uniqueColumnName] === changeValueId) {
        for (const columnsKey in this.displayColumns) {
          const columnsElement = this.displayColumns[columnsKey];
          if (columnsElement.type === 'radio' && columnsElement.field === field) {
            element[columnsElement.field] = this.currentRecords[index][field];
          }
          else if (columnsElement.type === 'radio') {
            element[columnsElement.field] = 0;
          }
        }
        break;
      }
    }

    for (const key in this.rows) {
      const element = this.rows[key];
      if (element[this.uniqueColumnName] === changeValueId) {
        for (const columnsKey in this.displayColumns) {
          const columnsElement = this.displayColumns[columnsKey];
          if (columnsElement.type === 'radio' && columnsElement.field === field) {
            element[columnsElement.field] = this.currentRecords[index][field];
          }
          else if (columnsElement.type === 'radio') {
            element[columnsElement.field] = 0;
          }
        }
        break;
      }
    }
    this.onRadioCheckboxChange.emit(this.rows);
  }

  onCheckBoxChange(index: number, field: any) {
    for (const key in this.displayColumns) {
      const element = this.displayColumns[key];
      if (element.type === 'checkbox' && element.field === field) {
        this.currentRecords[index][element.field] = this.currentRecords[index][element.field] === 0 ? 1 : 0;
      }
    }

    const changeValueId = this.currentRecords[index][this.uniqueColumnName];
    for (const key in this.filteredRecords) {
      const element = this.filteredRecords[key];
      if (element[this.uniqueColumnName] === changeValueId) {
        for (const columnsKey in this.displayColumns) {
          const columnsElement = this.displayColumns[columnsKey];
          if (columnsElement.type === 'checkbox' && columnsElement.field === field) {
            element[columnsElement.field] = this.currentRecords[index][field];
          }
        }
        break;
      }
    }

    for (const key in this.rows) {
      const element = this.rows[key];
      if (element[this.uniqueColumnName] === changeValueId) {
        for (const columnsKey in this.displayColumns) {
          const columnsElement = this.displayColumns[columnsKey];
          if (columnsElement.type === 'checkbox' && columnsElement.field === field) {
            element[columnsElement.field] = this.currentRecords[index][field];
          }
        }
        break;
      }
    }
    this.onRadioCheckboxChange.emit(this.rows);
  }

  onSelectChange(index: number, event: any) {
    this.onSelectValueChange.emit({ data: this.currentRecords[index], value: event.value });
  }

  onCleanerChange(index: number, event: any) {
    this.onCleanerValueChange.emit({ data: this.currentRecords[index], value: event.value });
  }

  itemValueConditionCheck = (element: any, item: any) => {
    let flag: boolean = false;
    try {
      switch (item.conditionType) {
        case "contains":
          flag = element[item.conditionField].toString().toLowerCase().includes(item.conditionValue.toLowerCase());
          break;
        case "equals":
          flag = element[item.conditionField].toString().toLowerCase() === item.conditionValue.toLowerCase();
          break;
        case "start with":
          flag = element[item.conditionField].toString().toLowerCase().startsWith(item.conditionValue.toLowerCase());
          break;
        case "end with":
          flag = element[item.conditionField].toString().toLowerCase().endsWith(item.conditionValue.toLowerCase());
          break;
      }
    } catch (error) {

    }
    return flag;
  };

  search(event: any) {
    this.searchSubject.next(event.target.value);
  }

  performSearch(searchText: string) {
    this.searchText = searchText;
    this.currentPage = 1;
    if (this.isServerSide) {
      this.searchOnTable.emit(this.searchText);
    }
    else {
      this.applySearch();
    }
  }

  applyFilterAPI() {
    if (this.filterOnMultipleColumn) {
      this.applySortAPI(this.filterValues);
    }
    else {
      const filterValuesArray = [];
      if (this.filterFormControls.filterValue.value.length > 0) {
        filterValuesArray.push({
          columns: this.filterFormControls.columns.value,
          operator: this.filterFormControls.operator.value,
          filterValue: this.filterFormControls.filterValue.value
        });
      }
      this.applySortAPI(filterValuesArray);
    }
  }

  applySortAPI(filterValuesArray: any) {
    if (this.sortOnMultipleColumn) {
      const finalArray = {
        filter: filterValuesArray,
        sort: this.sortValues
      };
      this.filterAndSort.emit(finalArray);
    }
    else {
      const sortValuesArray = [];

      if (this.sortFormControls.orderType.value !== "Default") {
        sortValuesArray.push({
          columns: this.sortFormControls.columns.value,
          orderType: this.sortFormControls.orderType.value
        });
      }
      const finalArray = {
        filter: filterValuesArray,
        sort: sortValuesArray
      };

      this.filterAndSort.emit(finalArray);
    }
  }

  applySearch() {
    this.currentPage = 1;
    this.filteredRecords = JSON.parse(JSON.stringify(this.rows));
    if (this.filterOnMultipleColumn) {
      for (const key in this.filterValues) {
        const element = this.filterValues[key];
        if (element.filterValue.length > 0) {
          this.filteredRecords = this.filteredRecords.filter((item: any) => this.onFilterSearch(item, element.columns, element.operator, element.filterValue));
        }
      }
    }
    else {
      const selectedFilterOptions = this.filterFormControls.columns.value;
      const operatorOptions = this.filterFormControls.operator.value;
      const text = this.filterFormControls.filterValue.value;
      this.filteredRecords = this.filteredRecords.filter((item: any) => this.onFilterSearch(item, selectedFilterOptions, operatorOptions, text));
    }
    this.filteredRecords = this.filteredRecords.filter((item: any) => this.onSearchCheck(item, this.searchText));
    this.applySorting();
  }

  onFilterSearch = (item: any, selectedFilterOptions: any, operatorOptions: any, text: any) => {
    if (text.length > 0) {
      let flag = false;
      let html = item[selectedFilterOptions].toString().toLowerCase();
      switch (selectedFilterOptions) {
        case "status":
          html = item[selectedFilterOptions] === 1 ? "active" : "de-active";
          break;
        case "is_for_new_user_or_amount_based":
          html = item[selectedFilterOptions] === 1 ? "New User" : "All User";
          break;
        default:
          const fieldItem = this.displayColumns.filter((item: any) => item.field === selectedFilterOptions);
          if (fieldItem[0].isTrueFalse) {
            html = item[selectedFilterOptions] === 0 ? "false" : "true";
          }
          break;
      }

      switch (operatorOptions) {
        case "contains":
          flag = html.includes(text.toLowerCase());
          break;
        case "equals":
          flag = html === text.toLowerCase();
          break;
        case "start with":
          flag = html.startsWith(text.toLowerCase());
          break;
        case "end with":
          flag = html.endsWith(text.toLowerCase());
          break;
      }
      return flag;
    }
    else {
      return true;
    }
  };

  onSearchCheck = (item: any, text: any) => {
    let flag = false;
    this.displayColumns.every((element: any) => {
      if (['id', 'action'].indexOf(element.field) === -1 && element.display) {
        flag = item[element.field].toString().toLowerCase().includes(text.toLowerCase());
      }
      return !flag;
    });
    return flag;
  };

  applySorting = () => {
    if (this.sortOnMultipleColumn) {
      let count = 0;
      for (const key in this.sortValues) {
        const element = this.sortValues[key];
        this.filteredRecords = this.loadFilter(this.filteredRecords, element.columns, element.orderType, count);
        if (element.orderType !== "Default") {
          count++;
        }
      }

    }
    else {
      const orderBy = this.sortFormControls.columns.value;
      const orderType = this.sortFormControls.orderType.value;
      this.filteredRecords = this.loadFilter(this.filteredRecords, orderBy, orderType);
    }
    this.loadCurrentPageRecord();
  };

  loadFilter(filteredRecords: any, orderBy: string, orderType: string, count: number = 0) {
    if (orderBy !== 'Default') {
      switch (orderType) {
        case "Ascending":
          if (isNaN(parseInt(filteredRecords[0][orderBy]))) {
            filteredRecords.sort((a: any, b: any) => {
              return this.additionalSort(a, b, count) && a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase());
            });
          }
          else {
            filteredRecords.sort((a: any, b: any) => {
              return this.additionalSort(a, b, count) && a[orderBy] - b[orderBy];
            });
          }
          break;
        case "Descending":
          if (isNaN(parseInt(filteredRecords[0][orderBy]))) {
            filteredRecords.sort((a: any, b: any) => {
              return this.additionalSort(a, b, count) && b[orderBy].toLowerCase().localeCompare(a[orderBy].toLowerCase());
            });
          }
          else {
            filteredRecords.sort((a: any, b: any) => {
              return this.additionalSort(a, b, count) && b[orderBy] - a[orderBy];
            });
          }
          break;
      }
    }
    return filteredRecords;
  }

  additionalSort(a: any, b: any, count: number) {
    let flag = true;
    if (count > 0) {
      for (let index = 0; index < count; index++) {
        const orderBy = this.sortValues[index].columns;

        if (isNaN(parseInt(a[orderBy]))) {
          flag = a[orderBy].toLowerCase() === b[orderBy].toLowerCase();
        }
        else {
          flag = a[orderBy] === b[orderBy];
        }

        if (!flag) {
          break;
        }
      }
    }

    return flag;
  }

  actionIcon(index: any, action: string) {
    switch (action) {
      case "fa-regular fa-eye":
        if (this.currentRecords[index]['status'] === 1) {
          return action + "-slash";
        }
        else {
          return action;
        }
      default:
        return action;
    }
  }

  actionTitle(index: any, action: string, title: string) {
    switch (action) {
      case "fa-regular fa-eye":
        if (this.currentRecords[index]['status'] === 1) {
          return 'De-Active';
        }
        else {
          return 'Active';
        }
      default:
        return title;
    }
  }

  hideShowColumn() {
    this.columnsPopupHideShow = !this.columnsPopupHideShow;
    this.filterPopupHideShow = false;
    this.sortPopupHideShow = false;
  }

  columnsHideShow(index: number) {
    this.columnsOptions[index].display = !this.columnsOptions[index].display;
    this.loadColumn(true);
  }

  resetColumn() {
    this.columnsOptions = JSON.parse(JSON.stringify(this.columns));
    this.loadColumn();
  }

  loadColumn(flag = false) {
    this.displayColumns = [];
    this.columnsOptions.forEach((element: any) => {
      if (element.display) {
        this.displayColumns.push(element);
      }
    });

    if (flag || this.filterOptions.length === 0) {
      this.filterOptions = this.displayColumns.filter((item: any) => item.filterable).map((item: any) => ({
        label: item.headerName,
        value: item.field
      }));
    }
    if (this.filterFormControls.columns.value === "") {
      if (this.filterOptions && this.filterOptions.length > 0) {
        this.filterFormControls.columns.setValue(this.filterOptions[0].value);
      }
      else {
        this.filterFormControls.columns.setValue("");
      }
    }

    if (flag || this.sortByOptions.length === 0) {
      this.sortByOptions = this.displayColumns.filter((item: any) => item.sortable).map((item: any) => ({
        label: item.headerName,
        value: item.field
      }));
    }

    this.loadDefaultDataForFilterAndSort();
  }

  loadDefaultDataForFilterAndSort = () => {
    if (this.filterValues.length === 0) {
      const filterValuesNew: any = [];
      for (const key in this.filterOptions) {
        const element = this.filterOptions[key];
        const currentValue = this.filterValues.filter((item: any) => item.columns === element.value);
        if (currentValue.length > 0) {
          filterValuesNew.push(currentValue[0]);
        }
        else {
          filterValuesNew.push({
            columns: element.value,
            columnsDisplay: element.label,
            operator: 'contains',
            filterValue: ''
          });
        }
      }
      this.filterValues = filterValuesNew;
    }

    if (this.sortValues.length === 0) {
      const sortValuesNew: any = [];
      for (const key in this.sortByOptions) {
        const element = this.sortByOptions[key];
        const currentValue = this.sortValues.filter((item: any) => item.columns === element.value);
        if (currentValue.length > 0) {
          sortValuesNew.push(currentValue[0]);
        }
        else {
          sortValuesNew.push({
            columns: element.value,
            columnsDisplay: element.label,
            orderType: 'Default'
          });
        }
      }
      this.sortValues = sortValuesNew;
    }

  };

  hideShowFilter() {
    this.columnsPopupHideShow = false;
    this.sortPopupHideShow = false;
    if (this.filterOnMultipleColumn) {
      const dialogRef = this.dialog.open(FilterOrderByDialogComponent, {
        data: {
          title: "Filter",
          data: this.filterOptions,
          defaultValue: this.filterValues
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.filterValues = result;
          if (this.isServerSide) {
            this.applyFilterAPI();
          }
          else {
            this.applySearch();
          }
        }
      });
    }
    else {
      this.filterPopupHideShow = !this.filterPopupHideShow;
    }
  }

  resetFilter() {
    this.isReset = true;
    if (this.filterOptions && this.filterOptions.length > 0) {
      this.filterFormControls.columns.setValue(this.filterOptions[0].value);
    }
    else {
      this.filterFormControls.columns.setValue("");
    }
    this.filterFormControls.operator.setValue('contains');
    this.filterFormControls.filterValue.setValue('');
    this.isReset = false;
    if (this.isServerSide) {
      this.applyFilterAPI();
    }
    else {
      this.applySearch();
    }
  }

  hideShowSort() {
    this.columnsPopupHideShow = false;
    this.filterPopupHideShow = false;
    if (this.sortOnMultipleColumn) {
      const dialogRef = this.dialog.open(FilterOrderByDialogComponent, {
        data: {
          title: "Sort",
          data: this.sortByOptions,
          defaultValue: this.sortValues
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.sortValues = result;
          if (this.isServerSide) {
            this.applyFilterAPI();
          }
          else {
            this.applySearch();
          }
        }
      });
    }
    else {
      this.sortPopupHideShow = !this.sortPopupHideShow;
    }
  }

  updateSort(index: number) {
    const sortOn = this.displayColumns[index];
    if (this.sortOnMultipleColumn) {
      for (const key in this.sortValues) {
        const element = this.sortValues[key];
        if (sortOn.field === element.columns) {
          element.orderType = element.orderType === 'Default' ? 'Ascending' : element.orderType === 'Ascending' ? 'Descending' : 'Default';
        }
      }
    }
    else {
      const orderBy = this.sortFormControls.columns.value;
      const orderType = this.sortFormControls.orderType.value;
      if (sortOn.field === orderBy) {
        this.sortFormControls.orderType.setValue(orderType === 'Default' ? 'Ascending' : orderType === 'Ascending' ? 'Descending' : 'Default');
      }
      else {
        this.sortFormControls.columns.setValue(sortOn.field);
        this.sortFormControls.orderType.setValue('Ascending');
      }
    }
    if (this.isServerSide) {
      this.applyFilterAPI();
    }
    else {
      this.applySearch();
    }
  }

  resetSort() {
    this.isReset = true;
    this.sortFormControls.columns.setValue('Default');
    this.sortFormControls.orderType.setValue('Default');
    this.isReset = false;
    if (this.isServerSide) {
      this.applyFilterAPI();
    }
    else {
      this.applySearch();
    }
  }

  checkSort(columns: string, orderType: string) {
    if (this.sortOnMultipleColumn) {
      const currentValue = this.sortValues.filter((item: any) => item.columns === columns);
      return currentValue.length > 0 && currentValue[0].orderType === orderType;
    }
    else {
      return this.sortFormControls.columns.value === columns && this.sortFormControls.orderType.value === orderType;
    }
  }

  checkNonSort(columns: string) {
    if (this.sortOnMultipleColumn) {
      const currentValue = this.sortValues.filter((item: any) => item.columns === columns);
      return currentValue.length > 0 && currentValue[0].orderType === 'Default';
    }
    else {
      return this.sortFormControls.columns.value !== columns;
    }
  }

  async download() {
    if (this.isServerSide) {
      this.downloadExcel.emit();
    }
    else {
      const header = this.displayColumns.filter((item: any) => item.exportable).map((item: any) => item.field);
      const headerDisplay = this.displayColumns.filter((item: any) => item.exportable).map((item: any) => item.headerName);
      const csv = this.filteredRecords.map((row: any, index: any) => header.map((fieldName: any) => this.itemValue(row, fieldName, index)).join(','));
      csv.unshift(headerDisplay.join(','));
      const csvArray = csv.join('\r\n');

      const blob = new Blob([csvArray], { type: 'text/csv' });
      saveAs(blob, this.downloadFileName + ".csv");
    }
  }

  action(index: number, action: string) {
    switch (action) {
      case "fa-solid fa-pen-to-square":
        this.edit(index);
        break;
      case "fa-regular fa-eye":
        this.activeDeactive(index);
        break;
      case "fa-solid fa-file":
        this.onDownloadPDF(index);
        break;
      case "fa-regular fa-circle-check":
        this.verified(index);
        break;
      case "fa-regular fa-circle-xmark":
        this.statusUpdate(index);
        break;
      case "fa-regular fa-trash-can":
        this.delete(index);
        break;
      case "fa-solid fa-list":
      case "fa-solid fa-location-dot":
      case "fa-solid fa-file-alt":
        this.redirect(index);
        break;
      case "fa-brands fa-servicestack":
        this.redirectService(index);
        break;
      case "fa-brands fa-product-hunt":
        this.redirectProduct(index);
        break;
      case "fa-solid fa-truck-fast":
        this.moreInfoDialog(index);
        break;
      case "fa-solid fa-star":
        this.productServiceRating(index);
        break;
      case "fa-solid fa-image":
        this.moreInfoDialog(index);
        break;
    }
  }

  edit(index: number) {
    this.editClick.emit(this.currentRecords[index]);
  }

  activeDeactive(index: number) {
    this.activeDeactiveClick.emit(this.currentRecords[index]);
  }

  onDownloadPDF(index: number) {
    this.onDownloadServicePDF.emit(this.currentRecords[index]);
  }

  moreInfoDialog(index: number) {
    this.moreInfoDialogClick.emit(this.currentRecords[index]);
  }

  productServiceRating(index: number) {
    this.productServiceRatingChange.emit(this.currentRecords[index]);
  }

  verified(index: number) {
    this.verifiedClick.emit(this.currentRecords[index]);
  }

  statusUpdate(index: number) {
    this.stausRejectClick.emit(this.currentRecords[index]);
  }

  @ViewChild('deleteSwal')
  public readonly deleteSwal!: SwalComponent;

  delete(index: number) {
    this.deleteSwal.fire();
    this.deleteIndex = index;
  }

  confirmDelete() {
    this.deleteClick.emit(this.currentRecords[this.deleteIndex]);
  }

  redirect(index: number) {
    this.redirectClick.emit(this.currentRecords[index]);
  }

  redirectProduct(index: number) {
    this.redirectProductClick.emit(this.currentRecords[index]);
  }

  redirectService(index: number) {
    this.redirectServiceClick.emit(this.currentRecords[index]);
  }
}