

export class StatusServiceWiseDiscountInput implements IStatusServiceWiseDiscountInput {
    /** The auto generated id from tbl service wise discount master */
    serviceWiseDiscountCouponId!: number;
    /** The updated status */
    status!: number;

    constructor(data?: IStatusServiceWiseDiscountInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.serviceWiseDiscountCouponId = _data["serviceWiseDiscountCouponId"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): StatusServiceWiseDiscountInput {
        data = typeof data === 'object' ? data : {};
        let result = new StatusServiceWiseDiscountInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["serviceWiseDiscountCouponId"] = this.serviceWiseDiscountCouponId;
        data["status"] = this.status;
        return data; 
    }
}

export interface IStatusServiceWiseDiscountInput {
    /** The auto generated id from tbl service wise discount master */
    serviceWiseDiscountCouponId: number;
    /** The updated status */
    status: number;
}

