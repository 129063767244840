export enum Paths {
  demo = 'demo',
  form = 'form',
  table = 'table',
  serverTable = 'server-table',
  button = 'button',
  card = 'card',
  dialog = 'dialog',
  input = 'input',
  select = 'select',
  radioCheckbox = 'radio-checkbox',

  authentication = "authentication",
  login = "login",
  signup = "signup",
  verify = 'verify',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
  main = 'main',

  application = "application",
  dashboard = "dashboard",
  master = "master",
  userType = "user-type",

  banner = "banner",
  add = "add",
  edit = "edit",

  country = "country",
  state = "state",
  city = "city",

  category = "category",

  blog = "blog",

  user = "user",
  list = "list",
  employment = "employment",
  request = "request",
  bookServiceForm = "book-service-form",
  bookServiceDetail = "book-service-detail",

  product = "product",
  productStock = "productStock",

  discount = "discount",
  categoryWise = "categoryWise",
  productWise = "productWise",
  serviceWise = "serviceWise",

  service = "service",

  couponCode = "couponCode",
  categoryWiseCode = "categoryWiseCode",
  productWiseCode = "productWiseCode",
  serviceWiseCode = "serviceWiseCode",
  carService = "carService",
  serviceDetails = "serviceDetails",

  fuelType = "fuelType",
  carBrand = "carBrand",
  carModel = "carModel",
  customNotification = "customNotification",

  oldCar = "oldCar",

  userCar = "userCar",
  userAddress = "myAddress",

  oldCarDeals = "oldCarDeals",
  oldCarDetails = "oldCarDetails",

  accessories = "accessories",
  accessoriesDetails = "accessories-details",

  cart = "cart",
  address = "address",
  coupons = "coupons",
  payment = "payment",
  checkout = "checkout",
  wishlist = "wishlist",
  inquiry = "inquiry",
  order = "order",
  serviceRequest = "serviceRequest",
  myservice = "myservice",
  userOrder = "userOrder",
  serviceCleaner = "serviceCleaner",
  userInquiry = "myInquiry",
  cleanerService = "cleanerService",
  blogs = "blogs",

  thankYou = "thank-you",
  profileDetail = "profile-update",
  changePassword = "changePassword",
  usedCarInquiry = "used-car-inquiry",

  userProfile = "profile",
  privacyPolicy = "privacy-policy",
  termsAndConditions = "terms-and-conditions",
  accountDeletion = "account-deletion",
  introduction = "introduction",

  splash = "splash",
  deleteAccount = "delete-account",

  servicePackage = "service-package",
  servicePackageList = "service-packages",
  allServiceRequest = "AllServiceRequest",
  allCleanerService = "AllCleanerService"
}
