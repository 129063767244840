import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'car-daily-select',
  standalone: false,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})
export class SelectComponent {

  @Input() isRequired = false;
  @Input() placeholder = 'Select...';
  @Input() classType = '';
  @Input() labelHide = false;
  @Input() labelValue = '';
  @Input() errorMessage = '';
  @Input() disabled = false;
  @Input() id = '';
  @Input() name = '';
  @Input() labelFieldName: any;
  @Input() valueFieldName: any;
  @Input("data") set data(value: any) {
    this.list = value;
    this.tempList = value;
  }
  @Input() control: FormControl | AbstractControl | undefined;
  @Input() value: any;
  @Input() mainElementStyle: object = {};
  @Input() selectStyle: object = {};
  @Input() inputType: 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' = 'primary';

  @Output() change: EventEmitter<any> = new EventEmitter();

  @ViewChild("input", { static: false }) inputElement: ElementRef | undefined;
  shown = false;
  keyword = "";
  list: any = [];
  tempList: any = [];

  constructor(private ele: ElementRef) { }

  getValue() {
    const temp = this.tempList.filter((element: any) => {
      if (element[this.valueFieldName] === this.control?.value || element[this.valueFieldName] === this.value) {
        return element;
      }
      return false;
    });
    return temp.length > 0 ? temp[0][this.labelFieldName] : "";
  }

  search(searchValue: any) {
    const val = searchValue.toLowerCase();
    const temp = this.tempList.filter((element: any) => {
      if (element[this.labelFieldName].toString().toLowerCase().indexOf(val) !== -1 || !val) {
        return element;
      }
      return false;
    });
    this.list = temp;
  }

  show() {
    this.control?.markAsTouched();
    this.shown = this.shown ? false : true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 200);
  }

  @HostListener('document:click', ['$event']) onClick(e: any) {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
  }

  onValueChange($event: any) {
    this.control?.patchValue($event[this.valueFieldName]);
    this.shown = false;

    this.change.emit({ value: $event[this.valueFieldName] });
  }

}