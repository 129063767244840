import { UserTypeDataInput } from './user-type-data-input.model';
import { UserDataInput } from './user-data-input.model';


export class AddNotificationInput implements IAddNotificationInput {
    /** The notification type should be User Type,User or All */
    notificationType!: string;
    /** The notification title with 255 character limit */
    notificationTitle!: string;
    /** The notification message name with 500 character limit */
    notificationMessage!: string;
    userTypeData!: UserTypeDataInput[];
    userData!: UserDataInput[];

    constructor(data?: IAddNotificationInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.notificationType = _data["notificationType"];
            this.notificationTitle = _data["notificationTitle"];
            this.notificationMessage = _data["notificationMessage"];
            if (Array.isArray(_data["userTypeData"])) {
                this.userTypeData = [] as any;
                for (let item of _data["userTypeData"])
                    this.userTypeData!.push(UserTypeDataInput.fromJS(item));
            }
            if (Array.isArray(_data["userData"])) {
                this.userData = [] as any;
                for (let item of _data["userData"])
                    this.userData!.push(UserDataInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): AddNotificationInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddNotificationInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["notificationType"] = this.notificationType;
        data["notificationTitle"] = this.notificationTitle;
        data["notificationMessage"] = this.notificationMessage;
        if (Array.isArray(this.userTypeData)) {
            data["userTypeData"] = [];
            for (let item of this.userTypeData)
                data["userTypeData"].push(item.toJSON());
        }
        if (Array.isArray(this.userData)) {
            data["userData"] = [];
            for (let item of this.userData)
                data["userData"].push(item.toJSON());
        }
        return data; 
    }
}

export interface IAddNotificationInput {
    /** The notification type should be User Type,User or All */
    notificationType: string;
    /** The notification title with 255 character limit */
    notificationTitle: string;
    /** The notification message name with 500 character limit */
    notificationMessage: string;
    userTypeData: UserTypeDataInput[];
    userData: UserDataInput[];
}

