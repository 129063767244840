

export class AddFuelTypeInput implements IAddFuelTypeInput {
    /** The fuel type name with 500 character limit */
    fuelTypeName!: string;

    constructor(data?: IAddFuelTypeInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fuelTypeName = _data["fuelTypeName"];
        }
    }

    static fromJS(data: any): AddFuelTypeInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddFuelTypeInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fuelTypeName"] = this.fuelTypeName;
        return data; 
    }
}

export interface IAddFuelTypeInput {
    /** The fuel type name with 500 character limit */
    fuelTypeName: string;
}

