

export class CategoryWiseCouponCodeOutputWithStatus implements ICategoryWiseCouponCodeOutputWithStatus {
    /** The auto generated id from tbl category wise coupon master */
    category_wise_coupon_code_id!: number;
    /** The auto generated id from tbl category master */
    category_id!: number;
    /** The category title with 500 character limit */
    category_title!: string;
    /** The category type will be SERVICE,PRODUCT or OTHER  */
    category_type!: string;
    /** The start date with 10 character limit */
    start_date!: string;
    /** The end date with 10 character limit */
    end_date!: string;
    /** Manage status of category wise coupon code. 0 For De-active, 1 For Active */
    status!: number;

    constructor(data?: ICategoryWiseCouponCodeOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.category_wise_coupon_code_id = _data["category_wise_coupon_code_id"];
            this.category_id = _data["category_id"];
            this.category_title = _data["category_title"];
            this.category_type = _data["category_type"];
            this.start_date = _data["start_date"];
            this.end_date = _data["end_date"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): CategoryWiseCouponCodeOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new CategoryWiseCouponCodeOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["category_wise_coupon_code_id"] = this.category_wise_coupon_code_id;
        data["category_id"] = this.category_id;
        data["category_title"] = this.category_title;
        data["category_type"] = this.category_type;
        data["start_date"] = this.start_date;
        data["end_date"] = this.end_date;
        data["status"] = this.status;
        return data; 
    }
}

export interface ICategoryWiseCouponCodeOutputWithStatus {
    /** The auto generated id from tbl category wise coupon master */
    category_wise_coupon_code_id: number;
    /** The auto generated id from tbl category master */
    category_id: number;
    /** The category title with 500 character limit */
    category_title: string;
    /** The category type will be SERVICE,PRODUCT or OTHER  */
    category_type: string;
    /** The start date with 10 character limit */
    start_date: string;
    /** The end date with 10 character limit */
    end_date: string;
    /** Manage status of category wise coupon code. 0 For De-active, 1 For Active */
    status: number;
}

