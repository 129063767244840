

export class UpdateCategoryWiseDiscountInput implements IUpdateCategoryWiseDiscountInput {
    /** The auto generated id from tbl category wise discount master */
    categoryWiseDiscountCouponId!: number;
    /** The auto generated id from tbl discount coupon master */
    discountCouponId!: number;
    /** The auto generated id from tbl category master */
    categoryId!: number;
    /** The start date with 10 character limit */
    startDate!: string;
    /** The end date with 10 character limit */
    endDate!: string;

    constructor(data?: IUpdateCategoryWiseDiscountInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.categoryWiseDiscountCouponId = _data["categoryWiseDiscountCouponId"];
            this.discountCouponId = _data["discountCouponId"];
            this.categoryId = _data["categoryId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
        }
    }

    static fromJS(data: any): UpdateCategoryWiseDiscountInput {
        data = typeof data === 'object' ? data : {};
        let result = new UpdateCategoryWiseDiscountInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["categoryWiseDiscountCouponId"] = this.categoryWiseDiscountCouponId;
        data["discountCouponId"] = this.discountCouponId;
        data["categoryId"] = this.categoryId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        return data; 
    }
}

export interface IUpdateCategoryWiseDiscountInput {
    /** The auto generated id from tbl category wise discount master */
    categoryWiseDiscountCouponId: number;
    /** The auto generated id from tbl discount coupon master */
    discountCouponId: number;
    /** The auto generated id from tbl category master */
    categoryId: number;
    /** The start date with 10 character limit */
    startDate: string;
    /** The end date with 10 character limit */
    endDate: string;
}

