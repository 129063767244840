

export class CouponCodeOutputWithStatus implements ICouponCodeOutputWithStatus {
    /** The auto generated id from tbl coupon code master */
    coupon_code_id!: number;
    /** The coupon code name with 500 character limit */
    coupon_code_name!: string;
    /** The coupon code description with 5000 character limit */
    coupon_code_description!: string;
    /** The coupon code with 500 character limit */
    coupon_code!: string;
    /** The coupon code type should be AMOUNT or PERCENTAGE */
    coupon_code_type!: string;
    /** The discount on decided coupon */
    discount!: number;
    /** The maximum discount on amount */
    maximum_discount_amount!: number;
    /** The minimum amount for discount */
    minimum_amount_for_discount!: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    is_for_new_user_or_amount_based!: number;
    /** Manage status of coupon code. 0 For De-active, 1 For Active */
    status!: number;

    constructor(data?: ICouponCodeOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.coupon_code_id = _data["coupon_code_id"];
            this.coupon_code_name = _data["coupon_code_name"];
            this.coupon_code_description = _data["coupon_code_description"];
            this.coupon_code = _data["coupon_code"];
            this.coupon_code_type = _data["coupon_code_type"];
            this.discount = _data["discount"];
            this.maximum_discount_amount = _data["maximum_discount_amount"];
            this.minimum_amount_for_discount = _data["minimum_amount_for_discount"];
            this.is_for_new_user_or_amount_based = _data["is_for_new_user_or_amount_based"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): CouponCodeOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new CouponCodeOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["coupon_code_id"] = this.coupon_code_id;
        data["coupon_code_name"] = this.coupon_code_name;
        data["coupon_code_description"] = this.coupon_code_description;
        data["coupon_code"] = this.coupon_code;
        data["coupon_code_type"] = this.coupon_code_type;
        data["discount"] = this.discount;
        data["maximum_discount_amount"] = this.maximum_discount_amount;
        data["minimum_amount_for_discount"] = this.minimum_amount_for_discount;
        data["is_for_new_user_or_amount_based"] = this.is_for_new_user_or_amount_based;
        data["status"] = this.status;
        return data; 
    }
}

export interface ICouponCodeOutputWithStatus {
    /** The auto generated id from tbl coupon code master */
    coupon_code_id: number;
    /** The coupon code name with 500 character limit */
    coupon_code_name: string;
    /** The coupon code description with 5000 character limit */
    coupon_code_description: string;
    /** The coupon code with 500 character limit */
    coupon_code: string;
    /** The coupon code type should be AMOUNT or PERCENTAGE */
    coupon_code_type: string;
    /** The discount on decided coupon */
    discount: number;
    /** The maximum discount on amount */
    maximum_discount_amount: number;
    /** The minimum amount for discount */
    minimum_amount_for_discount: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    is_for_new_user_or_amount_based: number;
    /** Manage status of coupon code. 0 For De-active, 1 For Active */
    status: number;
}

