<mat-card class="car-daily-card" [ngClass]="customCard">
  @if (title || subtitle) {
  <mat-card-header>
    <div class="car-daily-card-title">
      @if (title) {
      <mat-card-title>{{ title }}</mat-card-title>
      }
      @if (subtitle) {
      <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
      }
    </div>
    <div class="button-view ">
      @if(addAddressFlag){
      <car-daily-button type="button" label="+ Add New Address" btnType="secondary" (clickEvent)='addNewAddress()' />
      }
      @if(addCarFlag){
      <car-daily-button type="button" label="+ Add Car" btnType="secondary" (clickEvent)='addCar()' />
      }
    </div>
  </mat-card-header>
  }
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>