

export class UpdateProductWiseCouponCodeInput implements IUpdateProductWiseCouponCodeInput {
    /** The auto generated id from tbl product wise coupon code master */
    productWiseCouponCodeId!: number;
    /** The auto generated id from tbl coupon code master */
    couponCodeId!: number;
    /** The auto generated id from tbl product master */
    productId!: number;
    /** The start date with 10 character limit */
    startDate!: string;
    /** The end date with 10 character limit */
    endDate!: string;

    constructor(data?: IUpdateProductWiseCouponCodeInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.productWiseCouponCodeId = _data["productWiseCouponCodeId"];
            this.couponCodeId = _data["couponCodeId"];
            this.productId = _data["productId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
        }
    }

    static fromJS(data: any): UpdateProductWiseCouponCodeInput {
        data = typeof data === 'object' ? data : {};
        let result = new UpdateProductWiseCouponCodeInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["productWiseCouponCodeId"] = this.productWiseCouponCodeId;
        data["couponCodeId"] = this.couponCodeId;
        data["productId"] = this.productId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        return data; 
    }
}

export interface IUpdateProductWiseCouponCodeInput {
    /** The auto generated id from tbl product wise coupon code master */
    productWiseCouponCodeId: number;
    /** The auto generated id from tbl coupon code master */
    couponCodeId: number;
    /** The auto generated id from tbl product master */
    productId: number;
    /** The start date with 10 character limit */
    startDate: string;
    /** The end date with 10 character limit */
    endDate: string;
}

