

export class SignInInput implements ISignInInput {
    /** The user email address or mobile number with 320 character limit */
    emailOrMobileNo!: number;
    /** The user password with 100 character limit */
    password!: string;
    /** Login Type must be one of web or mobile. */
    loginType!: string;
    /** firebase token */
    firebaseToken!: string;

    constructor(data?: ISignInInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.emailOrMobileNo = _data["emailOrMobileNo"];
            this.password = _data["password"];
            this.loginType = _data["loginType"];
            this.firebaseToken = _data["firebaseToken"];
        }
    }

    static fromJS(data: any): SignInInput {
        data = typeof data === 'object' ? data : {};
        let result = new SignInInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["emailOrMobileNo"] = this.emailOrMobileNo;
        data["password"] = this.password;
        data["loginType"] = this.loginType;
        data["firebaseToken"] = this.firebaseToken;
        return data; 
    }
}

export interface ISignInInput {
    /** The user email address or mobile number with 320 character limit */
    emailOrMobileNo: number;
    /** The user password with 100 character limit */
    password: string;
    /** Login Type must be one of web or mobile. */
    loginType: string;
    /** firebase token */
    firebaseToken: string;
}

