

export class LogoutInput implements ILogoutInput {
    /** firebase token */
    firebaseToken!: string;

    constructor(data?: ILogoutInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firebaseToken = _data["firebaseToken"];
        }
    }

    static fromJS(data: any): LogoutInput {
        data = typeof data === 'object' ? data : {};
        let result = new LogoutInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firebaseToken"] = this.firebaseToken;
        return data; 
    }
}

export interface ILogoutInput {
    /** firebase token */
    firebaseToken: string;
}

