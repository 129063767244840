<div class="mb-2" [style]="mainElementStyle">
  @if (!labelHide && labelValue.length > 0) {
  <ng-container>
    <label class="form-label">
      {{labelValue}}
      @if(isRequired){
      <sup>*</sup>
      }
    </label>
  </ng-container>
  }
  <mat-select [ngClass]="{
        inputClassPrimary: inputType === 'primary',
        inputClassPrimaryWithoutBorder: inputType === 'primaryWithoutBorder',
        inputClassSecondary: inputType === 'secondary',
        inputClassSecondaryWithoutBorder: inputType === 'secondaryWithoutBorder',
        inputClassWarning: inputType === 'warning',
        inputClassWarningWithoutBorder: inputType === 'warningWithoutBorder',
        inputClassSuccess: inputType === 'success',
        inputClassSuccessWithoutBorder: inputType === 'successWithoutBorder',
        inputClassDisabled: disabled}" placeholder="Select..." [id]="id" [style]="selectStyle"
    [formControl]="$any(control)" ngDefaultControl [value]="value" [attr.disabled]="disabled" multiple>
    @for (item of data; track item;) {
    <mat-option [value]="item">{{item[labelFieldName]}}</mat-option>
    }
  </mat-select>

  @if (errorMessage.length > 0) {
  <ng-container>
    <label class="error-label">
      {{errorMessage}}
    </label>
  </ng-container>
  }
</div>