import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { SelectUserApplyForEmploymentListDataInput } from '../models/select-user-apply-for-employment-list-data-input.model';
import { SelectUserApplyForEmploymentListDataOutput } from '../models/select-user-apply-for-employment-list-data-output.model';
import { UserApplyForEmploymentAddInput } from '../models/user-apply-for-employment-add-input.model';
import { UserApplyForEmploymentAddOutput } from '../models/user-apply-for-employment-add-output.model';
import { UserApplyForEmploymentSelectByIdOutput } from '../models/user-apply-for-employment-select-by-id-output.model';
import { UserApplyForEmploymentStatusInput } from '../models/user-apply-for-employment-status-input.model';
import { UserApplyForEmploymentStatusOutput } from '../models/user-apply-for-employment-status-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class UserApplyForEmploymentServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return Application added successfully.
     */
    add(body: UserApplyForEmploymentAddInput): Observable<UserApplyForEmploymentAddOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<UserApplyForEmploymentAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserApplyForEmploymentAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<UserApplyForEmploymentAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserApplyForEmploymentAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserApplyForEmploymentAddOutput>(<any>null);
    }

    /**
     * @return Application status updated successfully.
     */
    approve(body: UserApplyForEmploymentStatusInput): Observable<UserApplyForEmploymentStatusOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/Approve";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processApprove(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processApprove(<any>response_);
                } catch (e) {
                    return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processApprove(response: HttpResponseBase): Observable<UserApplyForEmploymentStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserApplyForEmploymentStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserApplyForEmploymentStatusOutput>(<any>null);
    }

    /**
     * @return Application status updated successfully.
     */
    reject(body: UserApplyForEmploymentStatusInput): Observable<UserApplyForEmploymentStatusOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/Reject";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processReject(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processReject(<any>response_);
                } catch (e) {
                    return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processReject(response: HttpResponseBase): Observable<UserApplyForEmploymentStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserApplyForEmploymentStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserApplyForEmploymentStatusOutput>(<any>null);
    }

    /**
     * @return Application status updated successfully.
     */
    cancel(body: UserApplyForEmploymentStatusInput): Observable<UserApplyForEmploymentStatusOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/Cancel";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processCancel(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCancel(<any>response_);
                } catch (e) {
                    return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processCancel(response: HttpResponseBase): Observable<UserApplyForEmploymentStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserApplyForEmploymentStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserApplyForEmploymentStatusOutput>(<any>null);
    }

    /**
     * @return Application status updated successfully.
     */
    remove(body: UserApplyForEmploymentStatusInput): Observable<UserApplyForEmploymentStatusOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/Remove";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processRemove(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processRemove(<any>response_);
                } catch (e) {
                    return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserApplyForEmploymentStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processRemove(response: HttpResponseBase): Observable<UserApplyForEmploymentStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserApplyForEmploymentStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserApplyForEmploymentStatusOutput>(<any>null);
    }

    /**
     * @return Application selected successfully.
     */
    selectByUser(): Observable<UserApplyForEmploymentSelectByIdOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/SelectByUser";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectByUser(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByUser(<any>response_);
                } catch (e) {
                    return <Observable<UserApplyForEmploymentSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserApplyForEmploymentSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByUser(response: HttpResponseBase): Observable<UserApplyForEmploymentSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserApplyForEmploymentSelectByIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserApplyForEmploymentSelectByIdOutput>(<any>null);
    }

    /**
     * @return All application selected successfully.
     */
    selectUserApplyForEmploymentListData(body: SelectUserApplyForEmploymentListDataInput): Observable<SelectUserApplyForEmploymentListDataOutput> {
        let url_ = this.baseUrl + "/UserApplyForEmployment/SelectUserApplyForEmploymentListData";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectUserApplyForEmploymentListData(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectUserApplyForEmploymentListData(<any>response_);
                } catch (e) {
                    return <Observable<SelectUserApplyForEmploymentListDataOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectUserApplyForEmploymentListDataOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectUserApplyForEmploymentListData(response: HttpResponseBase): Observable<SelectUserApplyForEmploymentListDataOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectUserApplyForEmploymentListDataOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectUserApplyForEmploymentListDataOutput>(<any>null);
    }
}
