

export class UserTypeAddInput implements IUserTypeAddInput {
    /** The user type code with 255 character limit */
    userTypeCode!: string;
    /** The user type name with 255 character limit */
    userTypeName!: string;

    constructor(data?: IUserTypeAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTypeCode = _data["userTypeCode"];
            this.userTypeName = _data["userTypeName"];
        }
    }

    static fromJS(data: any): UserTypeAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new UserTypeAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTypeCode"] = this.userTypeCode;
        data["userTypeName"] = this.userTypeName;
        return data; 
    }
}

export interface IUserTypeAddInput {
    /** The user type code with 255 character limit */
    userTypeCode: string;
    /** The user type name with 255 character limit */
    userTypeName: string;
}

