

export class DiscountCouponOutputWithStatus implements IDiscountCouponOutputWithStatus {
    /** The auto generated id from tbl discount coupon master */
    discount_coupon_id!: number;
    /** The discount coupon name with 500 character limit */
    discount_coupon_name!: string;
    /** The discount coupon description with 5000 character limit */
    discount_coupon_description!: string;
    /** The discount coupon type should be AMOUNT or PERCENTAGE */
    discount_coupon_type!: string;
    /** The discount on decided coupon */
    discount!: number;
    /** The maximum discount on amount */
    maximum_discount_amount!: number;
    /** The minimum amount for discount */
    minimum_amount_for_discount!: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    is_for_new_user_or_amount_based!: number;
    /** Manage status of discount coupon. 0 For De-active, 1 For Active */
    status!: number;

    constructor(data?: IDiscountCouponOutputWithStatus) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.discount_coupon_id = _data["discount_coupon_id"];
            this.discount_coupon_name = _data["discount_coupon_name"];
            this.discount_coupon_description = _data["discount_coupon_description"];
            this.discount_coupon_type = _data["discount_coupon_type"];
            this.discount = _data["discount"];
            this.maximum_discount_amount = _data["maximum_discount_amount"];
            this.minimum_amount_for_discount = _data["minimum_amount_for_discount"];
            this.is_for_new_user_or_amount_based = _data["is_for_new_user_or_amount_based"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): DiscountCouponOutputWithStatus {
        data = typeof data === 'object' ? data : {};
        let result = new DiscountCouponOutputWithStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["discount_coupon_id"] = this.discount_coupon_id;
        data["discount_coupon_name"] = this.discount_coupon_name;
        data["discount_coupon_description"] = this.discount_coupon_description;
        data["discount_coupon_type"] = this.discount_coupon_type;
        data["discount"] = this.discount;
        data["maximum_discount_amount"] = this.maximum_discount_amount;
        data["minimum_amount_for_discount"] = this.minimum_amount_for_discount;
        data["is_for_new_user_or_amount_based"] = this.is_for_new_user_or_amount_based;
        data["status"] = this.status;
        return data; 
    }
}

export interface IDiscountCouponOutputWithStatus {
    /** The auto generated id from tbl discount coupon master */
    discount_coupon_id: number;
    /** The discount coupon name with 500 character limit */
    discount_coupon_name: string;
    /** The discount coupon description with 5000 character limit */
    discount_coupon_description: string;
    /** The discount coupon type should be AMOUNT or PERCENTAGE */
    discount_coupon_type: string;
    /** The discount on decided coupon */
    discount: number;
    /** The maximum discount on amount */
    maximum_discount_amount: number;
    /** The minimum amount for discount */
    minimum_amount_for_discount: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    is_for_new_user_or_amount_based: number;
    /** Manage status of discount coupon. 0 For De-active, 1 For Active */
    status: number;
}

