import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { AddServiceBookingInput } from '../models/add-service-booking-input.model';
import { AddServiceBookingOutput } from '../models/add-service-booking-output.model';
import { BulkCleanerAssignServiceBookingInput } from '../models/bulk-cleaner-assign-service-booking-input.model';
import { BulkCleanerAssignServiceBookingOutput } from '../models/bulk-cleaner-assign-service-booking-output.model';
import { ByItemIdServiceBookingOutput } from '../models/by-item-id-service-booking-output.model';
import { CleanerAssignServiceBookingInput } from '../models/cleaner-assign-service-booking-input.model';
import { CleanerAssignServiceBookingOutput } from '../models/cleaner-assign-service-booking-output.model';
import { SelectAwaitingServiceBookingOutput } from '../models/select-awaiting-service-booking-output.model';
import { SelectByServiceBookingIdOutput } from '../models/select-by-service-booking-id-output.model';
import { SelectConfirmedServiceBookingOutput } from '../models/select-confirmed-service-booking-output.model';
import { SelectServiceBookingByCleanerInput } from '../models/select-service-booking-by-cleaner-input.model';
import { SelectServiceBookingByCleanerOutput } from '../models/select-service-booking-by-cleaner-output.model';
import { SelectServiceBookingHistoryInput } from '../models/select-service-booking-history-input.model';
import { SelectServiceBookingHistoryOutput } from '../models/select-service-booking-history-output.model';
import { SelectServiceBookingListDataInput } from '../models/select-service-booking-list-data-input.model';
import { SelectServiceBookingListDataOutput } from '../models/select-service-booking-list-data-output.model';
import { SelectServiceImagePdfPathOutput } from '../models/select-service-image-pdf-path-output.model';
import { StatusServiceBookingInput } from '../models/status-service-booking-input.model';
import { StatusServiceBookingOutput } from '../models/status-service-booking-output.model';
import { UpdateServiceBookingInput } from '../models/update-service-booking-input.model';
import { UpdateServiceBookingOutput } from '../models/update-service-booking-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class ServiceBookingServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return Service booking added successfully.
     */
    add(body: AddServiceBookingInput): Observable<AddServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<AddServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<AddServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<AddServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = AddServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<AddServiceBookingOutput>(<any>null);
    }

    /**
     * @return Service booking updated successfully.
     */
    update(body: UpdateServiceBookingInput): Observable<UpdateServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<UpdateServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UpdateServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<UpdateServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UpdateServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UpdateServiceBookingOutput>(<any>null);
    }

    /**
     * @return Cleaner assigned successfully
     */
    bulkCleanerAssign(body: BulkCleanerAssignServiceBookingInput): Observable<BulkCleanerAssignServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/BulkCleanerAssign";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processBulkCleanerAssign(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processBulkCleanerAssign(<any>response_);
                } catch (e) {
                    return <Observable<BulkCleanerAssignServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<BulkCleanerAssignServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processBulkCleanerAssign(response: HttpResponseBase): Observable<BulkCleanerAssignServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = BulkCleanerAssignServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<BulkCleanerAssignServiceBookingOutput>(<any>null);
    }

    /**
     * @return Service booking status updated successfully.
     */
    status(body: StatusServiceBookingInput): Observable<StatusServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<StatusServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<StatusServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<StatusServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = StatusServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<StatusServiceBookingOutput>(<any>null);
    }

    /**
     * @return Cleaner assigned successfully
     */
    cleanerAssign(body: CleanerAssignServiceBookingInput): Observable<CleanerAssignServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/CleanerAssign";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processCleanerAssign(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCleanerAssign(<any>response_);
                } catch (e) {
                    return <Observable<CleanerAssignServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CleanerAssignServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processCleanerAssign(response: HttpResponseBase): Observable<CleanerAssignServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = CleanerAssignServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CleanerAssignServiceBookingOutput>(<any>null);
    }

    /**
     * @return Waiting service booking selected successfully.
     */
    selectAwaiting(): Observable<SelectAwaitingServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/SelectAwaiting";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAwaiting(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAwaiting(<any>response_);
                } catch (e) {
                    return <Observable<SelectAwaitingServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAwaitingServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAwaiting(response: HttpResponseBase): Observable<SelectAwaitingServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAwaitingServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAwaitingServiceBookingOutput>(<any>null);
    }

    /**
     * @return Confirmed service booking selected successfully.
     */
    selectConfirmed(): Observable<SelectConfirmedServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/SelectConfirmed";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectConfirmed(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectConfirmed(<any>response_);
                } catch (e) {
                    return <Observable<SelectConfirmedServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectConfirmedServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectConfirmed(response: HttpResponseBase): Observable<SelectConfirmedServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectConfirmedServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectConfirmedServiceBookingOutput>(<any>null);
    }

    /**
     * @return All Service booking selected successfully.
     */
    selectServiceBookingListData(body: SelectServiceBookingListDataInput): Observable<SelectServiceBookingListDataOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/SelectServiceBookingListData";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectServiceBookingListData(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectServiceBookingListData(<any>response_);
                } catch (e) {
                    return <Observable<SelectServiceBookingListDataOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectServiceBookingListDataOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectServiceBookingListData(response: HttpResponseBase): Observable<SelectServiceBookingListDataOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectServiceBookingListDataOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectServiceBookingListDataOutput>(<any>null);
    }

    /**
     * @return Service booking selected successfully.
     */
    selectByItemId(orderItemId: number): Observable<ByItemIdServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/selectByItemId/{orderItemId}";
        if (orderItemId === undefined || orderItemId === null)
            throw new Error("The parameter 'orderItemId' must be defined.");
        url_ = url_.replace("{orderItemId}", encodeURIComponent("" + orderItemId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectByItemId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByItemId(<any>response_);
                } catch (e) {
                    return <Observable<ByItemIdServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ByItemIdServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByItemId(response: HttpResponseBase): Observable<ByItemIdServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ByItemIdServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ByItemIdServiceBookingOutput>(<any>null);
    }

    /**
     * @return Service booking selected successfully.
     */
    selectServiceBookingHistory(body: SelectServiceBookingHistoryInput): Observable<SelectServiceBookingHistoryOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/SelectServiceBookingHistory";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectServiceBookingHistory(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectServiceBookingHistory(<any>response_);
                } catch (e) {
                    return <Observable<SelectServiceBookingHistoryOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectServiceBookingHistoryOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectServiceBookingHistory(response: HttpResponseBase): Observable<SelectServiceBookingHistoryOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectServiceBookingHistoryOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectServiceBookingHistoryOutput>(<any>null);
    }

    /**
     * @return Service booking selected successfully.
     */
    selectServiceBookingByCleaner(body: SelectServiceBookingByCleanerInput): Observable<SelectServiceBookingByCleanerOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/SelectServiceBookingByCleaner";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectServiceBookingByCleaner(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectServiceBookingByCleaner(<any>response_);
                } catch (e) {
                    return <Observable<SelectServiceBookingByCleanerOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectServiceBookingByCleanerOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectServiceBookingByCleaner(response: HttpResponseBase): Observable<SelectServiceBookingByCleanerOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectServiceBookingByCleanerOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectServiceBookingByCleanerOutput>(<any>null);
    }

    /**
     * @return Service Image link fetch successfully.
     */
    selectServiceImagePdfPath(serviceBookingId: number): Observable<SelectServiceImagePdfPathOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/selectServiceImagePdfPath/{serviceBookingId}";
        if (serviceBookingId === undefined || serviceBookingId === null)
            throw new Error("The parameter 'serviceBookingId' must be defined.");
        url_ = url_.replace("{serviceBookingId}", encodeURIComponent("" + serviceBookingId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectServiceImagePdfPath(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectServiceImagePdfPath(<any>response_);
                } catch (e) {
                    return <Observable<SelectServiceImagePdfPathOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectServiceImagePdfPathOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectServiceImagePdfPath(response: HttpResponseBase): Observable<SelectServiceImagePdfPathOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectServiceImagePdfPathOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectServiceImagePdfPathOutput>(<any>null);
    }

    /**
     * @return Service booking selected successfully.
     */
    selectById(serviceBookingId: number): Observable<SelectByServiceBookingIdOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/SelectById/{serviceBookingId}";
        if (serviceBookingId === undefined || serviceBookingId === null)
            throw new Error("The parameter 'serviceBookingId' must be defined.");
        url_ = url_.replace("{serviceBookingId}", encodeURIComponent("" + serviceBookingId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectById(<any>response_);
                } catch (e) {
                    return <Observable<SelectByServiceBookingIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectByServiceBookingIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectById(response: HttpResponseBase): Observable<SelectByServiceBookingIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectByServiceBookingIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectByServiceBookingIdOutput>(<any>null);
    }

    /**
     * @return Cleaner assigned successfully
     */
    bulkAssignCleaner(body: BulkCleanerAssignServiceBookingInput): Observable<BulkCleanerAssignServiceBookingOutput> {
        let url_ = this.baseUrl + "/ServiceBooking/BulkAssignCleaner";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processBulkAssignCleaner(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processBulkAssignCleaner(<any>response_);
                } catch (e) {
                    return <Observable<BulkCleanerAssignServiceBookingOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<BulkCleanerAssignServiceBookingOutput>><any>_observableThrow(response_);
        }));
    }

    protected processBulkAssignCleaner(response: HttpResponseBase): Observable<BulkCleanerAssignServiceBookingOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = BulkCleanerAssignServiceBookingOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<BulkCleanerAssignServiceBookingOutput>(<any>null);
    }
}
