import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../enums';
import {
  CartOutputData, CartServiceProxy, SelectActiveCartOutput,
  SelectActiveWishListOutput, WishListOutputData, WishListServiceProxy
} from '../../service-proxies';


@Component({
  selector: 'car-daily-top-nav',
  standalone: false,
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.scss'
})
export class TopNavComponent {

  @Input() isMenuOpen = true;
  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  cartData: CartOutputData[] = [];
  wishListData: WishListOutputData[] = [];

  /**
   *
   * This method is called once, just before the component is build.
   * 
   * @param cartServiceProxy
   * @param router
   * @param wishListServiceProxy
   * 
   * @memberof TopNavComponent 
    */
  constructor(
    private cartServiceProxy: CartServiceProxy,
    private router: Router,
    private wishListServiceProxy: WishListServiceProxy) {
  }

  ngOnInit() {
    this.getCartData();
  }

  onHideShow(): void {
    this.menuToggle.emit(!this.isMenuOpen);
  }

  // logout() {
  //   this.localStorageService.deleteData(LocalStorage.accessToken);
  //   this.localStorageService.deleteData(LocalStorage.refreshToken);
  //   this.localStorageService.deleteData(LocalStorage.loginData);
  //   this.localStorageService.deleteData(LocalStorage.applicationMenuId);
  //   this.localStorageService.deleteData(LocalStorage.applicationSubMenuId);
  //   this.router.navigate(["/" + Paths.authentication]);
  // }

  /**
   * Redirects to the wishlist page.
   * 
   * @memberof TopNavComponent
   */
  dashboard() {
    this.router.navigate([`${Paths.main}`]);
  }

  /**
   * Retrieves an active list of cart data.
   * 
   * @memberof TopNavComponent
   */
  getCartData() {
    try {
      this.cartServiceProxy.selectActive().subscribe((response: SelectActiveCartOutput) => {
        if (response.status === 1) {
          this.cartData = response.data;
          this.cartData = this.cartData.filter((a: any) => a.cart_list_type === 'PRODUCT');
        } else {
          this.cartData = [];
        }
        this.getWishList();
      });
    } catch (error) {
      console.error('Error getting cart:', error);
    }
  }

  /**
   * Retrieves an active list of product data.
   * 
   * @memberof TopNavComponent
   */
  getWishList() {
    this.wishListData = [];
    try {
      this.wishListServiceProxy.selectActive().subscribe((response: SelectActiveWishListOutput) => {
        if (response.status === 1) {
          this.wishListData = response.data;
        } else {
          this.wishListData = [];
        }
      });
    } catch (error) {
      console.error('Error getting product:', error);
    }
  }

  /**
   * Redirects to the cart page.
   * 
   * @memberof TopNavComponent
   */
  cartRedirect() {
    this.router.navigate([Paths.main + "/" + Paths.cart]);
  }

  /**
   * Redirects to the wishlist page.
   * 
   * @memberof TopNavComponent
   */
  wishlist() {
    this.router.navigate([`${Paths.main}/${Paths.wishlist}`]);
  }

  goToHomePage() {
    this.router.navigate([Paths.main]);
  }
}
