

export class ChangePasswordInput implements IChangePasswordInput {
    /** The password of user with 100 character limit */
    password!: string;
    /** The old password of user with 100 character limit */
    oldPassword!: string;

    constructor(data?: IChangePasswordInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.password = _data["password"];
            this.oldPassword = _data["oldPassword"];
        }
    }

    static fromJS(data: any): ChangePasswordInput {
        data = typeof data === 'object' ? data : {};
        let result = new ChangePasswordInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["password"] = this.password;
        data["oldPassword"] = this.oldPassword;
        return data; 
    }
}

export interface IChangePasswordInput {
    /** The password of user with 100 character limit */
    password: string;
    /** The old password of user with 100 character limit */
    oldPassword: string;
}

