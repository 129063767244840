<div class="confirmationDialog">
  <div class="title">{{data.title}}</div>
  <div class="content">
    @if (data.title === 'Filter') {
    <form class="validate-form container-fluid" [formGroup]="filterForm">
      <ng-container formArrayName="filter">
        <div class="row mb-2 titleDisplay">
          <div class="col-sm-4 col-xl-4">
            <b class="form-title">
              Field
            </b>
          </div>
          <div class="col-sm-4 col-xl-4">
            <b class="form-title">
              Operator
            </b>
          </div>
          <div class="col-sm-4 col-xl-4">
            <b class="form-title">
              Filter Value
            </b>
          </div>
        </div>
        @for (filterOptionsItem of filterFormControls; track filterOptionsItem) {
        <div class="row" [formGroup]="filterOptionsItem">
          <div class="col-sm-4 col-xl-4">
            <label class="form-title">
              {{filterOptionsItem.value.columnsDisplay}}
            </label>
          </div>
          <div class="col-sm-4 col-xl-4">
            <car-daily-select id="operator" name="operator" inputType="primary"
              [control]="filterOptionsItem.controls.operator" labelValue=""
              [mainElementStyle]="{'margin-bottom': '0.5rem !important'}" placeholder="Please select operator"
              labelFieldName="title" valueFieldName="value" [data]="operatorOptionsList">
            </car-daily-select>
          </div>
          <div class="col-sm-4 col-xl-4">
            <car-daily-input [control]="filterOptionsItem.controls.filterValue" type="text" id="filterValue"
              name="filterValue" labelValue="" placeholder="Search"></car-daily-input>
          </div>
        </div>
        }
      </ng-container>
    </form>
    }
    @else if (data.title === 'Sort') {
    <form class="validate-form container-fluid" [formGroup]="sortForm">
      <ng-container formArrayName="sort">
        <div class="row mb-2 titleDisplay">
          <div class="col-sm-4 col-xl-4">
            <b class="form-title">
              Field
            </b>
          </div>
          <div class="col-sm-8 col-xl-8">
            <b class="form-title">
              Order Type
            </b>
          </div>
        </div>
        @for (sortOptionsItem of sortFormControls; track sortOptionsItem) {
        <div class="row" [formGroup]="sortOptionsItem">
          <div class="col-sm-6 col-xl-6">
            <car-daily-select id="columns" name="columns" inputType="primary"
              [control]="sortOptionsItem.controls.columns" labelValue=""
              [mainElementStyle]="{'margin-bottom': '0.5rem !important'}" placeholder="Please select sorting field"
              labelFieldName="label" valueFieldName="value" [data]="sortByOptions">
            </car-daily-select>
          </div>
          <div class="col-sm-6 col-xl-6">
            <car-daily-select id="orderType" name="orderType" inputType="primary"
              [control]="sortOptionsItem.controls.orderType" labelValue=""
              [mainElementStyle]="{'margin-bottom': '0.5rem !important'}" placeholder="Please select order type"
              labelFieldName="title" valueFieldName="value" [data]="sortOptionsList">
            </car-daily-select>
          </div>
        </div>
        }
      </ng-container>
    </form>
    }
  </div>
  <div class="footer">
    <car-daily-button type="button" label="Apply" btnType="primary" (click)="apply()"></car-daily-button>
    <car-daily-button (clickEvent)='clear()' type="button" label="Clear" btnType="warning"></car-daily-button>
    <car-daily-button (clickEvent)='dialogRef.close();' type="button" label="Close"
      btnType="secondary"></car-daily-button>
  </div>
</div>