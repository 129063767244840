import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationService } from './validation.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  subject = new Subject<any>();
  subject2 = new Subject<any>();

  /**
  * A getter to access the form controls of the vehicle document form.
  * 
  * @memberof CommonService
  * */
  formControls(formName: FormGroup): any {
    return formName.controls;
  }

  /**
  * Retrieves the error message for a specified form field using the validation service.
  *
  * @param {any} fieldName - The name of the form field.
  * @returns {string} The error message for the specified form field.
  * @memberof CommonService
  * */
  getFormErrorMessage(validationService: ValidationService, fieldName: any, validationMessage: any = []) {
    return validationService.getFormErrorMessage(fieldName, validationMessage);
  }

  /**
  * Determines the input type for a specified form field using the validation service.
  * 
  * @memberof CommonService
  * */
  getFormInputType(validationService: ValidationService, fieldName: any): 'primary' | 'primaryWithoutBorder' | 'secondary' | 'secondaryWithoutBorder' | 'warning' | 'warningWithoutBorder' | 'success' | 'successWithoutBorder' | 'error' | 'errorWithoutBorder' {
    return validationService.getFormInputType(fieldName, 'secondary');
  }

  /**
   * 
   * @param controlName 
   * 
   * @memberof CommonService
   */
  onInputGetNumbers(formName: FormGroup, controlName: any) {
    const inputNumbers = formName.get(controlName);
    if (inputNumbers) {
      const extractedNumbers = inputNumbers.value.replace(/[^0-9]*/g, '');
      if (inputNumbers.value !== extractedNumbers) {
        formName.patchValue({
          [controlName]: extractedNumbers,
        });
      }
    }
  }

  changeData(productData: any) {
    this.subject.next(productData);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  changeWishListData(wishListData: any) {
    this.subject2.next(wishListData);
  }

  getWishListData(): Observable<any> {
    return this.subject2.asObservable();
  }
}
