<button [ngClass]="{
    btnClassPrimary: btnType === 'primary',
    btnClassPrimaryWithoutBorder: btnType === 'primaryWithoutBorder',
    btnClassPrimaryOutline: btnType === 'primaryOutline',
    btnClassSecondary: btnType === 'secondary',
    btnClassSecondaryWithoutBorder: btnType === 'secondaryWithoutBorder',
    btnClassSecondaryOutline: btnType === 'secondaryOutline',
    btnClassWarning: btnType === 'warning',
    btnClassWarningOutline: btnType === 'warningOutline',
    btnClassSuccess: btnType === 'success',
    btnClassError: btnType === 'error',
    btnClassDisabled: disabled
}" [type]="type" [id]="id" [name]="name" [disabled]="disabled || isSubmitted" [style]="buttonStyle"
    [matTooltip]="itemToolTipText()" (click)="onClickButton($event)">

    @if (leftIcon != '') {
    <mat-icon [fontIcon]="leftIcon"></mat-icon>
    }

    @if (isSubmitted) {
    <div class="labelClass" [style]="labelStyle">
        {{ label }} in progress
        <span id="loading"></span>
    </div>
    } @else {
    <div class="labelClass" [style]="labelStyle">
        {{ label }}
    </div>
    }

    @if (rightIcon != '') {
    <mat-icon [fontIcon]="rightIcon"></mat-icon>
    }
</button>