import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { ByTypeIdUserOutput } from '../models/by-type-id-user-output.model';
import { ChangePasswordInput } from '../models/change-password-input.model';
import { ChangePasswordOutput } from '../models/change-password-output.model';
import { DeleteAccountOutput } from '../models/delete-account-output.model';
import { ForgotPasswordInput } from '../models/forgot-password-input.model';
import { ForgotPasswordOutput } from '../models/forgot-password-output.model';
import { LogoutInput } from '../models/logout-input.model';
import { LogoutOutput } from '../models/logout-output.model';
import { ProfileUpdateOutput } from '../models/profile-update-output.model';
import { ProfileUpdateUserInput } from '../models/profile-update-user-input.model';
import { ResetPasswordInput } from '../models/reset-password-input.model';
import { ResetPasswordOutput } from '../models/reset-password-output.model';
import { SelectAllUserWithCarOutput } from '../models/select-all-user-with-car-output.model';
import { SignInInput } from '../models/sign-in-input.model';
import { SignInOutput } from '../models/sign-in-output.model';
import { SignInWithSocialMediaInput } from '../models/sign-in-with-social-media-input.model';
import { SignInWithSocialMediaOutput } from '../models/sign-in-with-social-media-output.model';
import { SignUpUserInput } from '../models/sign-up-user-input.model';
import { SignUpUserOutput } from '../models/sign-up-user-output.model';
import { UserListDataInput } from '../models/user-list-data-input.model';
import { UserListDataOutput } from '../models/user-list-data-output.model';
import { UserStatusInput } from '../models/user-status-input.model';
import { UserStatusOutput } from '../models/user-status-output.model';
import { VerifyUserOutput } from '../models/verify-user-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class UserServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return User registered successfully. Please verify your email address.
     */
    signUp(body: SignUpUserInput): Observable<SignUpUserOutput> {
        let url_ = this.baseUrl + "/User/SignUp";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSignUp(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSignUp(<any>response_);
                } catch (e) {
                    return <Observable<SignUpUserOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SignUpUserOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSignUp(response: HttpResponseBase): Observable<SignUpUserOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SignUpUserOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SignUpUserOutput>(<any>null);
    }

    /**
     * @return User verified successfully.
     */
    verify(): Observable<VerifyUserOutput> {
        let url_ = this.baseUrl + "/User/Verify";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processVerify(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processVerify(<any>response_);
                } catch (e) {
                    return <Observable<VerifyUserOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VerifyUserOutput>><any>_observableThrow(response_);
        }));
    }

    protected processVerify(response: HttpResponseBase): Observable<VerifyUserOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = VerifyUserOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VerifyUserOutput>(<any>null);
    }

    /**
     * @return User login successfully.
     */
    signIn(body: SignInInput): Observable<SignInOutput> {
        let url_ = this.baseUrl + "/User/SignIn";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSignIn(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSignIn(<any>response_);
                } catch (e) {
                    return <Observable<SignInOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SignInOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSignIn(response: HttpResponseBase): Observable<SignInOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SignInOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SignInOutput>(<any>null);
    }

    /**
     * @return User login successfully.
     */
    signInWithSocialMedia(body: SignInWithSocialMediaInput): Observable<SignInWithSocialMediaOutput> {
        let url_ = this.baseUrl + "/User/SignInWithSocialMedia";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSignInWithSocialMedia(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSignInWithSocialMedia(<any>response_);
                } catch (e) {
                    return <Observable<SignInWithSocialMediaOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SignInWithSocialMediaOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSignInWithSocialMedia(response: HttpResponseBase): Observable<SignInWithSocialMediaOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SignInWithSocialMediaOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SignInWithSocialMediaOutput>(<any>null);
    }

    /**
     * @return Forgot password url sent on email successfully.
     */
    forgotPassword(body: ForgotPasswordInput): Observable<ForgotPasswordOutput> {
        let url_ = this.baseUrl + "/User/ForgotPassword";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processForgotPassword(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processForgotPassword(<any>response_);
                } catch (e) {
                    return <Observable<ForgotPasswordOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ForgotPasswordOutput>><any>_observableThrow(response_);
        }));
    }

    protected processForgotPassword(response: HttpResponseBase): Observable<ForgotPasswordOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ForgotPasswordOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ForgotPasswordOutput>(<any>null);
    }

    /**
     * @return Password reset successfully.
     */
    resetPassword(body: ResetPasswordInput): Observable<ResetPasswordOutput> {
        let url_ = this.baseUrl + "/User/ResetPassword";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processResetPassword(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processResetPassword(<any>response_);
                } catch (e) {
                    return <Observable<ResetPasswordOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ResetPasswordOutput>><any>_observableThrow(response_);
        }));
    }

    protected processResetPassword(response: HttpResponseBase): Observable<ResetPasswordOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ResetPasswordOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ResetPasswordOutput>(<any>null);
    }

    /**
     * @return User status updated successfully.
     */
    status(body: UserStatusInput): Observable<UserStatusOutput> {
        let url_ = this.baseUrl + "/User/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<UserStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<UserStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserStatusOutput>(<any>null);
    }

    /**
     * @return User data found successfully.
     */
    userList(body: UserListDataInput): Observable<UserListDataOutput> {
        let url_ = this.baseUrl + "/User/UserList";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUserList(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUserList(<any>response_);
                } catch (e) {
                    return <Observable<UserListDataOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<UserListDataOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUserList(response: HttpResponseBase): Observable<UserListDataOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = UserListDataOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<UserListDataOutput>(<any>null);
    }

    /**
     * @return User data found successfully.
     */
    selectActiveByTypeId(userTypeId: number): Observable<ByTypeIdUserOutput> {
        let url_ = this.baseUrl + "/User/selectActiveByTypeId/{userTypeId}";
        if (userTypeId === undefined || userTypeId === null)
            throw new Error("The parameter 'userTypeId' must be defined.");
        url_ = url_.replace("{userTypeId}", encodeURIComponent("" + userTypeId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActiveByTypeId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveByTypeId(<any>response_);
                } catch (e) {
                    return <Observable<ByTypeIdUserOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ByTypeIdUserOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveByTypeId(response: HttpResponseBase): Observable<ByTypeIdUserOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ByTypeIdUserOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ByTypeIdUserOutput>(<any>null);
    }

    /**
     * @return Password reset successfully.
     */
    changePassword(body: ChangePasswordInput): Observable<ChangePasswordOutput> {
        let url_ = this.baseUrl + "/User/ChangePassword";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processChangePassword(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processChangePassword(<any>response_);
                } catch (e) {
                    return <Observable<ChangePasswordOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ChangePasswordOutput>><any>_observableThrow(response_);
        }));
    }

    protected processChangePassword(response: HttpResponseBase): Observable<ChangePasswordOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ChangePasswordOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ChangePasswordOutput>(<any>null);
    }

    /**
     * @return Profile updated successfully. Please verify your email address.
     */
    profileUpdate(body: ProfileUpdateUserInput): Observable<ProfileUpdateOutput> {
        let url_ = this.baseUrl + "/User/ProfileUpdate";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processProfileUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processProfileUpdate(<any>response_);
                } catch (e) {
                    return <Observable<ProfileUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ProfileUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processProfileUpdate(response: HttpResponseBase): Observable<ProfileUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ProfileUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ProfileUpdateOutput>(<any>null);
    }

    /**
     * @return Logout successfully.
     */
    logout(body: LogoutInput): Observable<LogoutOutput> {
        let url_ = this.baseUrl + "/User/Logout";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processLogout(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processLogout(<any>response_);
                } catch (e) {
                    return <Observable<LogoutOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<LogoutOutput>><any>_observableThrow(response_);
        }));
    }

    protected processLogout(response: HttpResponseBase): Observable<LogoutOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = LogoutOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<LogoutOutput>(<any>null);
    }

    /**
     * @return User list with car selected successfully.
     */
    selectUserWithCar(): Observable<SelectAllUserWithCarOutput> {
        let url_ = this.baseUrl + "/User/SelectUserWithCar";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectUserWithCar(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectUserWithCar(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllUserWithCarOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllUserWithCarOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectUserWithCar(response: HttpResponseBase): Observable<SelectAllUserWithCarOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllUserWithCarOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllUserWithCarOutput>(<any>null);
    }

    /**
     * @return Account deleted successfully.
     */
    deleteAccount(): Observable<DeleteAccountOutput> {
        let url_ = this.baseUrl + "/User/DeleteAccount";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processDeleteAccount(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processDeleteAccount(<any>response_);
                } catch (e) {
                    return <Observable<DeleteAccountOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<DeleteAccountOutput>><any>_observableThrow(response_);
        }));
    }

    protected processDeleteAccount(response: HttpResponseBase): Observable<DeleteAccountOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = DeleteAccountOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<DeleteAccountOutput>(<any>null);
    }
}
