

export class UpdateProductWiseDiscountInput implements IUpdateProductWiseDiscountInput {
    /** The auto generated id from tbl product wise discount master */
    productWiseDiscountCouponId!: number;
    /** The auto generated id from tbl discount coupon master */
    discountCouponId!: number;
    /** The auto generated id from tbl product master */
    productId!: number;
    /** The start date with 10 character limit */
    startDate!: string;
    /** The end date with 10 character limit */
    endDate!: string;

    constructor(data?: IUpdateProductWiseDiscountInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.productWiseDiscountCouponId = _data["productWiseDiscountCouponId"];
            this.discountCouponId = _data["discountCouponId"];
            this.productId = _data["productId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
        }
    }

    static fromJS(data: any): UpdateProductWiseDiscountInput {
        data = typeof data === 'object' ? data : {};
        let result = new UpdateProductWiseDiscountInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["productWiseDiscountCouponId"] = this.productWiseDiscountCouponId;
        data["discountCouponId"] = this.discountCouponId;
        data["productId"] = this.productId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        return data; 
    }
}

export interface IUpdateProductWiseDiscountInput {
    /** The auto generated id from tbl product wise discount master */
    productWiseDiscountCouponId: number;
    /** The auto generated id from tbl discount coupon master */
    discountCouponId: number;
    /** The auto generated id from tbl product master */
    productId: number;
    /** The start date with 10 character limit */
    startDate: string;
    /** The end date with 10 character limit */
    endDate: string;
}

