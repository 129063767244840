import { SelectProductWiseCouponCodeListDataInputFilter } from './select-product-wise-coupon-code-list-data-input-filter.model';
import { SelectProductWiseCouponCodeListDataInputOrderBy } from './select-product-wise-coupon-code-list-data-input-order-by.model';


export class SelectProductWiseCouponCodeListDataInput implements ISelectProductWiseCouponCodeListDataInput {
    /** The Coupon code id */
    couponCodeId!: number;
    /** The search from data */
    search!: string;
    /** The Page no */
    page!: number;
    /** The no of records on per page */
    noOf!: number;
    filter!: SelectProductWiseCouponCodeListDataInputFilter[];
    orderBy!: SelectProductWiseCouponCodeListDataInputOrderBy[];

    constructor(data?: ISelectProductWiseCouponCodeListDataInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.couponCodeId = _data["couponCodeId"];
            this.search = _data["search"];
            this.page = _data["page"];
            this.noOf = _data["noOf"];
            if (Array.isArray(_data["filter"])) {
                this.filter = [] as any;
                for (let item of _data["filter"])
                    this.filter!.push(SelectProductWiseCouponCodeListDataInputFilter.fromJS(item));
            }
            if (Array.isArray(_data["orderBy"])) {
                this.orderBy = [] as any;
                for (let item of _data["orderBy"])
                    this.orderBy!.push(SelectProductWiseCouponCodeListDataInputOrderBy.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SelectProductWiseCouponCodeListDataInput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectProductWiseCouponCodeListDataInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["couponCodeId"] = this.couponCodeId;
        data["search"] = this.search;
        data["page"] = this.page;
        data["noOf"] = this.noOf;
        if (Array.isArray(this.filter)) {
            data["filter"] = [];
            for (let item of this.filter)
                data["filter"].push(item.toJSON());
        }
        if (Array.isArray(this.orderBy)) {
            data["orderBy"] = [];
            for (let item of this.orderBy)
                data["orderBy"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ISelectProductWiseCouponCodeListDataInput {
    /** The Coupon code id */
    couponCodeId: number;
    /** The search from data */
    search: string;
    /** The Page no */
    page: number;
    /** The no of records on per page */
    noOf: number;
    filter: SelectProductWiseCouponCodeListDataInputFilter[];
    orderBy: SelectProductWiseCouponCodeListDataInputOrderBy[];
}

