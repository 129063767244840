import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'car-daily-filter-order-by-dialog',
  templateUrl: './filter-order-by-dialog.component.html',
  styleUrls: ['./filter-order-by-dialog.component.scss']
})
export class FilterOrderByDialogComponent {

  public filterForm: FormGroup | any;
  public sortForm: FormGroup | any;
  sortByOptions: any = [];
  operatorOptionsList: any = [
    {
      title: "Contains",
      value: "contains"
    },
    {
      title: "Equals",
      value: "equals"
    },
    {
      title: "Start with",
      value: "start with"
    },
    {
      title: "End with",
      value: "end with"
    }
  ];
  sortOptionsList: any = [
    {
      title: "Default",
      value: "Default"
    },
    {
      title: "Ascending",
      value: "Ascending"
    },
    {
      title: "Descending",
      value: "Descending"
    }
  ];

  constructor(public dialogRef: MatDialogRef<FilterOrderByDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.clear(false);
  }

  get filterFormControls(): any {
    return this.filterForm.controls.filter.controls;
  }

  get sortFormControls(): any {
    return this.sortForm.controls.sort.controls;
  }

  apply() {
    switch (this.data.title) {
      case "Filter":
        this.dialogRef.close(this.filterForm.get("filter")?.value);
        break;
      case "Sort":
        for (const key in this.sortForm.get("sort")?.value) {
          const element = this.sortForm.get("sort")?.value[key];
          for (const key in this.data.data) {
            const dataElement = this.data.data[key];
            if (dataElement.value === element.columns) {
              element.columnsDisplay = dataElement.label;
              break;
            }
          }
        }
        this.dialogRef.close(this.sortForm.get("sort")?.value);
        break;
    }
  }

  clear(isClear = true) {
    switch (this.data.title) {
      case "Filter":
        const filterArray = [];
        if (this.data.data && this.data.data.length > 0) {
          for (const key in this.data.data) {
            const element = this.data.data[key];
            if (isClear) {
              filterArray.push(
                new FormGroup({
                  columns: new FormControl(element.value),
                  columnsDisplay: new FormControl(element.label),
                  operator: new FormControl('contains'),
                  filterValue: new FormControl('')
                })
              );
            }
            else {
              filterArray.push(
                new FormGroup({
                  columns: new FormControl(this.data.defaultValue[key].columns),
                  columnsDisplay: new FormControl(this.data.defaultValue[key].columnsDisplay),
                  operator: new FormControl(this.data.defaultValue[key].operator),
                  filterValue: new FormControl(this.data.defaultValue[key].filterValue)
                })
              );
            }
          }
        }

        this.filterForm = new FormGroup({
          filter: this.fb.array(filterArray)
        });
        break;
      case "Sort":
        const sortArray = [];
        if (this.data.data && this.data.data.length > 0) {
          this.sortByOptions = this.data.data.map((item: any) => ({
            label: item.label,
            value: item.value
          }));

          for (const key in this.data.data) {
            const element = this.data.data[key];
            if (isClear) {
              sortArray.push(
                new FormGroup({
                  columns: new FormControl(element.value),
                  columnsDisplay: new FormControl(element.label),
                  orderType: new FormControl('Default'),
                })
              );
            }
            else {
              sortArray.push(
                new FormGroup({
                  columns: new FormControl(this.data.defaultValue[key].columns),
                  columnsDisplay: new FormControl(this.data.defaultValue[key].columnsDisplay),
                  orderType: new FormControl(this.data.defaultValue[key].orderType),
                })
              );
            }
          }
        }

        this.sortForm = new FormGroup({
          sort: this.fb.array(sortArray)
        });
        break;
    }
  }
}