import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { LocalStorageService } from './';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  apiCall = new Subject<any>();
  triggerLocalStorage = new Subject<any>();
  applicationModuleData: any;

  constructor(private localStorageService: LocalStorageService, private router: Router) { }

  userLogout() {
    this.localStorageService.deleteAllData();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 500);
  }

  setTriggerLocalStorage(flag: any) {
    this.triggerLocalStorage.next(flag);
  }

  getTriggerLocalStorage(): Observable<any> {
    return this.triggerLocalStorage.asObservable();
  }
  setApiCall(flag: any) {
    this.apiCall.next(flag);
  }

  getApiCall(): Observable<any> {
    return this.apiCall.asObservable();
  }

}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmPasswordValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}