import { SelectCategoryWiseDiscountListDataInputFilter } from './select-category-wise-discount-list-data-input-filter.model';
import { SelectCategoryWiseDiscountListDataInputOrderBy } from './select-category-wise-discount-list-data-input-order-by.model';


export class SelectCategoryWiseDiscountListDataInput implements ISelectCategoryWiseDiscountListDataInput {
    /** The Page no */
    discountCouponId!: number;
    /** The search from data */
    search!: string;
    /** The Page no */
    page!: number;
    /** The no of records on per page */
    noOf!: number;
    filter!: SelectCategoryWiseDiscountListDataInputFilter[];
    orderBy!: SelectCategoryWiseDiscountListDataInputOrderBy[];

    constructor(data?: ISelectCategoryWiseDiscountListDataInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.discountCouponId = _data["discountCouponId"];
            this.search = _data["search"];
            this.page = _data["page"];
            this.noOf = _data["noOf"];
            if (Array.isArray(_data["filter"])) {
                this.filter = [] as any;
                for (let item of _data["filter"])
                    this.filter!.push(SelectCategoryWiseDiscountListDataInputFilter.fromJS(item));
            }
            if (Array.isArray(_data["orderBy"])) {
                this.orderBy = [] as any;
                for (let item of _data["orderBy"])
                    this.orderBy!.push(SelectCategoryWiseDiscountListDataInputOrderBy.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SelectCategoryWiseDiscountListDataInput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectCategoryWiseDiscountListDataInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["discountCouponId"] = this.discountCouponId;
        data["search"] = this.search;
        data["page"] = this.page;
        data["noOf"] = this.noOf;
        if (Array.isArray(this.filter)) {
            data["filter"] = [];
            for (let item of this.filter)
                data["filter"].push(item.toJSON());
        }
        if (Array.isArray(this.orderBy)) {
            data["orderBy"] = [];
            for (let item of this.orderBy)
                data["orderBy"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ISelectCategoryWiseDiscountListDataInput {
    /** The Page no */
    discountCouponId: number;
    /** The search from data */
    search: string;
    /** The Page no */
    page: number;
    /** The no of records on per page */
    noOf: number;
    filter: SelectCategoryWiseDiscountListDataInputFilter[];
    orderBy: SelectCategoryWiseDiscountListDataInputOrderBy[];
}

