import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../enums';

@Component({
  selector: 'car-daily-card',
  standalone: false,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {

  @Input() title = '';
  @Input() subtitle = '';
  @Input() customCard = '';
  @Input() addAddressFlag = false;
  @Input() addCarFlag = false;
  @Input() orderItemId = 0;

  constructor(private router: Router) {
  }

  addNewAddress() {
    this.router.navigate(["/" + Paths.application + "/" + Paths.master + "/" + Paths.userAddress + "/" + this.orderItemId]);
  }

  addCar() {
    this.router.navigate(["/" + Paths.application + "/" + Paths.userCar + "/" + this.orderItemId]);
  }

}
