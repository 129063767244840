import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { AddCarEnquiryInput } from '../models/add-car-enquiry-input.model';
import { AddCarEnquiryOutput } from '../models/add-car-enquiry-output.model';
import { SelectActiveCarEnquiryByCarOutput } from '../models/select-active-car-enquiry-by-car-output.model';
import { SelectAllCarEnquiryByCarOutput } from '../models/select-all-car-enquiry-by-car-output.model';
import { SelectAllCarEnquiryByUserOutput } from '../models/select-all-car-enquiry-by-user-output.model';
import { SelectAllCarEnquiryOutput } from '../models/select-all-car-enquiry-output.model';
import { StatusCarEnquiryInput } from '../models/status-car-enquiry-input.model';
import { StatusCarEnquiryOutput } from '../models/status-car-enquiry-output.model';
import { VerificationCarEnquiryInput } from '../models/verification-car-enquiry-input.model';
import { VerificationCarEnquiryOutput } from '../models/verification-car-enquiry-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class CarEnquiryServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return Car enquiry added successfully.
     */
    add(body: AddCarEnquiryInput): Observable<AddCarEnquiryOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<AddCarEnquiryOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<AddCarEnquiryOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<AddCarEnquiryOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = AddCarEnquiryOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<AddCarEnquiryOutput>(<any>null);
    }

    /**
     * @return Car enquiry updated successfully.
     */
    verificationEnquiry(body: VerificationCarEnquiryInput): Observable<VerificationCarEnquiryOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/VerificationEnquiry";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processVerificationEnquiry(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processVerificationEnquiry(<any>response_);
                } catch (e) {
                    return <Observable<VerificationCarEnquiryOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VerificationCarEnquiryOutput>><any>_observableThrow(response_);
        }));
    }

    protected processVerificationEnquiry(response: HttpResponseBase): Observable<VerificationCarEnquiryOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = VerificationCarEnquiryOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VerificationCarEnquiryOutput>(<any>null);
    }

    /**
     * @return Car enquiry status updated successfully.
     */
    status(body: StatusCarEnquiryInput): Observable<StatusCarEnquiryOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<StatusCarEnquiryOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<StatusCarEnquiryOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<StatusCarEnquiryOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = StatusCarEnquiryOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<StatusCarEnquiryOutput>(<any>null);
    }

    /**
     * @return All car enquiry selected successfully.
     */
    selectAllByUserId(userId: number): Observable<SelectAllCarEnquiryByUserOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/SelectAllByUserId/{userId}";
        if (userId === undefined || userId === null)
            throw new Error("The parameter 'userId' must be defined.");
        url_ = url_.replace("{userId}", encodeURIComponent("" + userId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAllByUserId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllByUserId(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllCarEnquiryByUserOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllCarEnquiryByUserOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllByUserId(response: HttpResponseBase): Observable<SelectAllCarEnquiryByUserOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllCarEnquiryByUserOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllCarEnquiryByUserOutput>(<any>null);
    }

    /**
     * @return All car enquiry selected successfully.
     */
    selectAllByCarId(carId: number): Observable<SelectAllCarEnquiryByCarOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/SelectAllByCarId/{carId}";
        if (carId === undefined || carId === null)
            throw new Error("The parameter 'carId' must be defined.");
        url_ = url_.replace("{carId}", encodeURIComponent("" + carId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAllByCarId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllByCarId(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllCarEnquiryByCarOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllCarEnquiryByCarOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllByCarId(response: HttpResponseBase): Observable<SelectAllCarEnquiryByCarOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllCarEnquiryByCarOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllCarEnquiryByCarOutput>(<any>null);
    }

    /**
     * @return Active car enquiry selected successfully.
     */
    selectActiveByCarId(carId: number): Observable<SelectActiveCarEnquiryByCarOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/SelectActiveByCarId/{carId}";
        if (carId === undefined || carId === null)
            throw new Error("The parameter 'carId' must be defined.");
        url_ = url_.replace("{carId}", encodeURIComponent("" + carId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActiveByCarId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveByCarId(<any>response_);
                } catch (e) {
                    return <Observable<SelectActiveCarEnquiryByCarOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectActiveCarEnquiryByCarOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveByCarId(response: HttpResponseBase): Observable<SelectActiveCarEnquiryByCarOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectActiveCarEnquiryByCarOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectActiveCarEnquiryByCarOutput>(<any>null);
    }

    /**
     * @return Car enquiry status updated successfully.
     */
    selectAll(): Observable<SelectAllCarEnquiryOutput> {
        let url_ = this.baseUrl + "/CarEnquiry/SelectAll";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<SelectAllCarEnquiryOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectAllCarEnquiryOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<SelectAllCarEnquiryOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectAllCarEnquiryOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectAllCarEnquiryOutput>(<any>null);
    }
}
