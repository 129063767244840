import { ProductPictureOutput } from './product-picture-output.model';
import { ServicePictureOutput } from './service-picture-output.model';


export class CartOutputData implements ICartOutputData {
    /** The wish list id of wish list master */
    cart_id!: number;
    /** The user id of user master */
    user_id!: number;
    /** The user name with 255 character */
    user_name!: string;
    /** The wish list type should be "SERVICE" or "PRODUCT" */
    cart_list_type!: string;
    /** The product service id will be service id or product id from  product  or service master */
    product_service_id!: number;
    /** The item name will based on selection of product or service" */
    item_name!: string;
    /** The item MRP will based on selection of product or service" */
    item_mrp!: string;
    /** The item selling will based on selection of product or service" */
    item_selling_price!: string;
    /** The added cart count */
    count!: number;
    /** Manage wish list. 0 For De-active, 1 For Active */
    status!: number;
    /** This category id */
    category_id!: number;
    /** This service package id */
    service_package_id!: number;
    product_picture!: ProductPictureOutput[];
    service_picture!: ServicePictureOutput[];

    constructor(data?: ICartOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.cart_id = _data["cart_id"];
            this.user_id = _data["user_id"];
            this.user_name = _data["user_name"];
            this.cart_list_type = _data["cart_list_type"];
            this.product_service_id = _data["product_service_id"];
            this.item_name = _data["item_name"];
            this.item_mrp = _data["item_mrp"];
            this.item_selling_price = _data["item_selling_price"];
            this.count = _data["count"];
            this.status = _data["status"];
            this.category_id = _data["category_id"];
            this.service_package_id = _data["service_package_id"];
            if (Array.isArray(_data["product_picture"])) {
                this.product_picture = [] as any;
                for (let item of _data["product_picture"])
                    this.product_picture!.push(ProductPictureOutput.fromJS(item));
            }
            if (Array.isArray(_data["service_picture"])) {
                this.service_picture = [] as any;
                for (let item of _data["service_picture"])
                    this.service_picture!.push(ServicePictureOutput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): CartOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new CartOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["cart_id"] = this.cart_id;
        data["user_id"] = this.user_id;
        data["user_name"] = this.user_name;
        data["cart_list_type"] = this.cart_list_type;
        data["product_service_id"] = this.product_service_id;
        data["item_name"] = this.item_name;
        data["item_mrp"] = this.item_mrp;
        data["item_selling_price"] = this.item_selling_price;
        data["count"] = this.count;
        data["status"] = this.status;
        data["category_id"] = this.category_id;
        data["service_package_id"] = this.service_package_id;
        if (Array.isArray(this.product_picture)) {
            data["product_picture"] = [];
            for (let item of this.product_picture)
                data["product_picture"].push(item.toJSON());
        }
        if (Array.isArray(this.service_picture)) {
            data["service_picture"] = [];
            for (let item of this.service_picture)
                data["service_picture"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ICartOutputData {
    /** The wish list id of wish list master */
    cart_id: number;
    /** The user id of user master */
    user_id: number;
    /** The user name with 255 character */
    user_name: string;
    /** The wish list type should be "SERVICE" or "PRODUCT" */
    cart_list_type: string;
    /** The product service id will be service id or product id from  product  or service master */
    product_service_id: number;
    /** The item name will based on selection of product or service" */
    item_name: string;
    /** The item MRP will based on selection of product or service" */
    item_mrp: string;
    /** The item selling will based on selection of product or service" */
    item_selling_price: string;
    /** The added cart count */
    count: number;
    /** Manage wish list. 0 For De-active, 1 For Active */
    status: number;
    /** This category id */
    category_id: number;
    /** This service package id */
    service_package_id: number;
    product_picture: ProductPictureOutput[];
    service_picture: ServicePictureOutput[];
}

