import { PictureOutput } from './picture-output.model';


export class WishListOutputData implements IWishListOutputData {
    /** The wish list id of wish list master */
    wishlist_id!: number;
    /** The user id of user master */
    user_id!: number;
    /** The user name with 255 character */
    user_name!: string;
    /** The wish list type should be "SERVICE" or "PRODUCT" */
    wish_list_type!: string;
    /** The product service id will be service id or product id from  product  or service master */
    product_service_id!: number;
    /** The item name will based on selection of product or service" */
    item_name!: string;
    /** The item MRP will based on selection of product or service" */
    item_mrp!: string;
    /** The item selling will based on selection of product or service" */
    item_selling_price!: string;
    /** Manage wish list. 0 For De-active, 1 For Active */
    status!: number;
    item_picture_data!: PictureOutput[];
    /** The count of product */
    avg_rating!: number;

    constructor(data?: IWishListOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.wishlist_id = _data["wishlist_id"];
            this.user_id = _data["user_id"];
            this.user_name = _data["user_name"];
            this.wish_list_type = _data["wish_list_type"];
            this.product_service_id = _data["product_service_id"];
            this.item_name = _data["item_name"];
            this.item_mrp = _data["item_mrp"];
            this.item_selling_price = _data["item_selling_price"];
            this.status = _data["status"];
            if (Array.isArray(_data["item_picture_data"])) {
                this.item_picture_data = [] as any;
                for (let item of _data["item_picture_data"])
                    this.item_picture_data!.push(PictureOutput.fromJS(item));
            }
            this.avg_rating = _data["avg_rating"];
        }
    }

    static fromJS(data: any): WishListOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new WishListOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["wishlist_id"] = this.wishlist_id;
        data["user_id"] = this.user_id;
        data["user_name"] = this.user_name;
        data["wish_list_type"] = this.wish_list_type;
        data["product_service_id"] = this.product_service_id;
        data["item_name"] = this.item_name;
        data["item_mrp"] = this.item_mrp;
        data["item_selling_price"] = this.item_selling_price;
        data["status"] = this.status;
        if (Array.isArray(this.item_picture_data)) {
            data["item_picture_data"] = [];
            for (let item of this.item_picture_data)
                data["item_picture_data"].push(item.toJSON());
        }
        data["avg_rating"] = this.avg_rating;
        return data; 
    }
}

export interface IWishListOutputData {
    /** The wish list id of wish list master */
    wishlist_id: number;
    /** The user id of user master */
    user_id: number;
    /** The user name with 255 character */
    user_name: string;
    /** The wish list type should be "SERVICE" or "PRODUCT" */
    wish_list_type: string;
    /** The product service id will be service id or product id from  product  or service master */
    product_service_id: number;
    /** The item name will based on selection of product or service" */
    item_name: string;
    /** The item MRP will based on selection of product or service" */
    item_mrp: string;
    /** The item selling will based on selection of product or service" */
    item_selling_price: string;
    /** Manage wish list. 0 For De-active, 1 For Active */
    status: number;
    item_picture_data: PictureOutput[];
    /** The count of product */
    avg_rating: number;
}

